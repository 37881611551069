import { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import userImg from "../../assets/imgs/user.png";
import { Guest } from "../../interfaces/Guest";
import { User } from "../../interfaces/User";
import userState from "../../states/userState";
import useStartCall from "../../utils/startCall";
import { ToastType, notify } from "../../utils/toast";
import Avatar from "../Avatar";
import Button from "../Button";
import {
  ButtonContainer,
  Container,
  GuestInfo,
  GuestSection,
  ResidentHr,
  ResidentInfo,
  ResidentSection,
  ResidentTextData,
  VehicleSubSection,
} from "./styles";
import awsRoot from "../../utils/awsRoot";

interface GuestDetailsProps {
  guest: Guest;
  resident: User;
  onCall: () => void;
}

export default function GuestDetails({
  guest,
  resident,
  onCall,
}: GuestDetailsProps) {
  const user = useRecoilValue(userState);

  const [isLoadingCall, setIsLoadingCall] = useState(false);

  const startCall = useStartCall();

  const towerLabel = user?.residential?.labelType !== "house" ? "Torre" : "Rua";

  const residentTypeLabels = useMemo(
    () => ({
      owner: "Proprietário",
      tenant:
        user?.residential?.labelType === "commercial"
          ? "Locatário"
          : "Inquilino",
      employee: "Funcionário",
    }),
    [user]
  );

  type ResidentTypeLabels = "owner" | "tenant" | "employee";

  const residentLabel =
    user?.residential?.labelType !== "commercial"
      ? "Morador"
      : "Pessoa interna";
  const aptLabel =
    user?.residential?.labelType === "house"
      ? "Casa"
      : user?.residential?.labelType === "commercial"
      ? "Sala"
      : "Apt";
  const apartmentLabel =
    user?.residential?.labelType === "house"
      ? "Casa"
      : user?.residential?.labelType === "commercial"
      ? "Sala"
      : "Apartamento";
  const guestLabel =
    user?.residential?.labelType !== "commercial"
      ? "Visitante"
      : "Pessoa externa";

  const personTypes = {
    guest: guestLabel,
    condominiumService: "Prestador de serviço de condomínio",
    apartmentService: `Prestador de serviço de ${apartmentLabel.toLocaleLowerCase()}`,
    deliveryman: "Entregador",
    appDriver: "Motorista de aplicativo",
  };
  type PersonType =
    | "guest"
    | "condominiumService"
    | "apartmentService"
    | "deliveryman"
    | "appDriver";

  const fullName = `${guest.name ?? ""} ${guest.surname ?? ""}`;
  const residentFullName = `${resident.name ?? ""} ${resident.surname ?? ""}`;
  const photo = guest.imageUUID
    ? `${awsRoot}guests_images/` + guest.imageUUID + ".jpg"
    : userImg;
  const residentPhoto = resident.imageUUID
    ? `${awsRoot}users_images/` + resident.imageUUID + ".jpg"
    : userImg;

  return (
    <Container>
      <GuestSection>
        <Avatar
          size="sm"
          src={photo}
          alt={`Foto do(a) ${guestLabel.toLocaleLowerCase()} ${guest.name}`}
        />
        <GuestInfo>
          <h4>{fullName}</h4>
          {guest.type && <p>{personTypes[guest.type as PersonType]}</p>}
          {guest.cpf && <p>CPF: {guest.cpf}</p>}
          {guest.rg && <p>RG: {guest.rg}</p>}
          {guest.vehicle && (
            <VehicleSubSection>
              <h5>Informações do veículo</h5>
              <p>Modelo: {guest.carModel}</p>
              <p>Placa: {guest.carPlate}</p>
              <p>Cor: {guest.carColor}</p>
            </VehicleSubSection>
          )}
        </GuestInfo>
      </GuestSection>

      <ResidentSection>
        <ResidentHr>
          <h6>{residentLabel}</h6>
        </ResidentHr>

        <ResidentInfo>
          <ResidentTextData>
            <h4>{residentFullName}</h4>
            {resident.permission === "resident" && resident.residentType && (
              <p>
                {
                  residentTypeLabels[
                    resident.residentType as ResidentTypeLabels
                  ]
                }
              </p>
            )}
            {resident.condominium?.name &&
              resident.condominium.name !== resident.tower?.name && (
                <p>Cond: {resident.condominium.name}</p>
              )}
            {resident.tower?.name && (
              <p>
                {towerLabel}: {resident.tower.name}
              </p>
            )}
            {resident.apartment?.name && (
              <p>
                {aptLabel}: {resident.apartment.name}
              </p>
            )}
          </ResidentTextData>
          <Avatar
            size="sm"
            src={residentPhoto}
            alt={`Foto d${
              residentLabel === "Morador" ? "o" : "a"
            } ${residentLabel.toLocaleLowerCase()} ${resident.name}`}
          />
        </ResidentInfo>
        <ButtonContainer>
          <Button
            onClick={async () => {
              setIsLoadingCall(true);

              try {
                await startCall(resident);

                onCall();
              } catch {
                notify(
                  "Ops... Ocorreu algum erro ao iniciar a chamada!",
                  ToastType.error
                );
              } finally {
                setIsLoadingCall(false);
              }
            }}
            loading={isLoadingCall}
            title="Ligar"
          />
        </ButtonContainer>
      </ResidentSection>
    </Container>
  );
}
