import { useState } from "react";
import { MdLogout } from "react-icons/md";
import { SyncLoader } from "react-spinners";
import { useRecoilValue } from "recoil";
import userImg from "../../assets/imgs/user.png";
import useLogout from "../../hooks/useLogout";
import userState from "../../states/userState";
import Avatar from "../Avatar";
import Button from "../Button";
import { Profile as StyledProfile } from "./styles";
import awsRoot from "../../utils/awsRoot";

export default function Profile() {
  const user = useRecoilValue(userState);

  const logout = useLogout();

  const [loggingOut, setLoggingOut] = useState(false);

  const fullName = `${user.name ?? ""} ${user.surname ?? ""}`;
  const photo = user.imageUUID
    ? `${awsRoot}users_images/` + user.imageUUID + ".jpg"
    : userImg;

  const handleLogout = async () => {
    setLoggingOut(true);

    await logout();

    setLoggingOut(false);
  };

  return (
    <StyledProfile>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          width: "100%",
        }}
      >
        <div style={{ position: "absolute", left: 0 }}>
          <Button
            size="xs"
            disabled={loggingOut}
            title={
              loggingOut ? (
                <SyncLoader size={4} color="#ffffff" />
              ) : (
                <MdLogout />
              )
            }
            onClick={handleLogout}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Avatar src={photo} alt={`Foto do(a) ${fullName}`} />
          <h5 style={{ marginBottom: "0" }}>{fullName}</h5>
          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginBottom: ".5rem",
              fontSize: ".9rem",
            }}
          >
            {user.permission === "generalPorter"
              ? user.residential?.labelType === "commercial"
                ? "Recepcionista"
                : "Porteiro geral"
              : user.residential?.labelType === "commercial"
              ? "Recepcionista"
              : "Porteiro local"}
          </p>
          <p style={{ textAlign: "center" }}>Vinculado:</p>
          <ul>
            {user.permission === "generalPorter" ? (
              <li>
                {user.residential?.name && (
                  <>
                    <b>Res: </b>
                    <span>{user.residential?.name ?? ""}</span>
                  </>
                )}
              </li>
            ) : (
              <li>
                {user.residential?.name &&
                  user.residential?.name !== user.condominium?.name && (
                    <>
                      <b>Res: </b>
                      <span>{user.residential.name ?? ""}</span>
                    </>
                  )}
                <br />
                <b>Cond: </b>
                {user.condominium?.name ?? ""}
              </li>
            )}
          </ul>
        </div>
      </div>
    </StyledProfile>
  );
}
