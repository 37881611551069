import { api } from "../services/api";

export default async function getCompany(
  resId: number,
  condId: number,
  towerId: number,
  apId: number
) {
  const { data } = await api.get(
    `/companies/all/1/0/${resId}/${condId}/${towerId}/${apId}/`
  );

  const [company] = data.rows;
  if (company) return company.name;
}
