import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "../Modal";
import Webcam from "react-webcam";
import { Container, TakePictureButton } from "./styles";
import { getFirebaseToken } from "../../firebase";
import { api } from "../../services/api";

interface WebcamModalProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUserImage: (img: string) => void;
  shouldVerifyUserImage: boolean;
  style?: React.CSSProperties;
}

export default function WebcamModal({
  setOpen,
  setUserImage,
  shouldVerifyUserImage,
  style = {} as React.CSSProperties,
}: WebcamModalProps) {
  const webcamRef = useRef<Webcam>(null);

  const checkPhoto = useCallback(async (image: any) => {
    const form = new FormData();
    form.append("fileData", image);

    const token = (await getFirebaseToken()) as string;
    form.append("fcm", token);

    try {
      await api.post("user/testUserPhotoByPorterWeb", form, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const webcam = useCallback(() => {
    return (
      <Container>
        <Webcam
          screenshotFormat="image/jpeg"
          ref={webcamRef}
          forceScreenshotSourceSize
          mirrored
          videoConstraints={{ height: 720, width: 1280 }}
          style={{ width: "100%", height: "100%" }}
        />
        <TakePictureButton
          onClick={async () => {
            if (webcamRef && webcamRef.current) {
              const imageSrc = webcamRef.current.getScreenshot() as string;

              const commaIndex = imageSrc.indexOf(",");
              const img = imageSrc.substring(commaIndex + 1);

              setUserImage(imageSrc);
              if (shouldVerifyUserImage) checkPhoto(img);
              setOpen(false);
            }
          }}
        >
          <div />
        </TakePictureButton>
      </Container>
    );
  }, [checkPhoto, setOpen, setUserImage, shouldVerifyUserImage]);

  return (
    <Modal
      style={style}
      active={true}
      content={webcam()}
      onClose={() => setOpen(false)}
    />
  );
}
