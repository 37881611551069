import _ from "lodash";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import AutomationCard from "../../components/AutomationCard";
import Layout from "../../components/Layout";
import { Automation } from "../../interfaces/Automation";
import { api } from "../../services/api";
import userState from "../../states/userState";
import { AutomationCardsContainer, Container } from "./styles";

export default function OpenPorts() {
  const [user] = useRecoilState(userState);
  const [automations, setAutomations] = useState<Automation[][]>([]);

  useEffect(() => {
    const { residentialId, condominiumId } = user;

    const endpoint =
      user.permission === "localPorter"
        ? `/automation/devices_per_residential/${residentialId}?condominiumId=${condominiumId}`
        : `/automation/devices_per_residential/${residentialId}`;

    api.get(endpoint).then((response) => {
      const automationGroupedByDoors = _.groupBy(response.data, "Door.name");

      setAutomations(Object.values(automationGroupedByDoors));
    });
  }, [user.condominiumId, user.permission, user.residentialId]);

  return (
    <Layout>
      <Container>
        <AutomationCardsContainer>
          {automations &&
            automations.map((automation: any, i: number) => (
              <AutomationCard key={i} automation={automation} />
            ))}
        </AutomationCardsContainer>
      </Container>
    </Layout>
  );
}
