import { useState } from "react";
import awsRoot from "../../utils/awsRoot";
import { Container } from "./styles";
import { useSetRecoilState } from "recoil";
import { imagePreviewState } from "../../states/imagePreviewState";

interface ChatMessageProps {
  isReceivedMessage: boolean;
  messageContent: string;
  time: string;
  imageUUID?: string;
  username?: string;
}

export default function ChatMessage({
  isReceivedMessage,
  messageContent,
  time,
  imageUUID,
  username,
}: ChatMessageProps) {
  const messageTypeClass = isReceivedMessage ? "received" : "sent";

  const setImagePreview = useSetRecoilState(imagePreviewState);

  const imgUrl = `${awsRoot}chat_images/${imageUUID}.jpg`;

  return (
    <Container className={messageTypeClass}>
      {imageUUID ? (
        <>
          <img
            src={imgUrl}
            alt=""
            style={{ maxWidth: "100%", maxHeight: 200, cursor: "zoom-in" }}
            onClick={() => setImagePreview(imgUrl)}
          />
          <p style={{ marginTop: 5 }}>{messageContent}</p>
        </>
      ) : (
        <p>{messageContent}</p>
      )}
      <div>
        {username && (
          <span>
            ~{" "}
            {username.length > 20
              ? username.substring(0, 21) + "..."
              : username}
          </span>
        )}
        <time dateTime={time}>{time}</time>
      </div>
    </Container>
  );
}
