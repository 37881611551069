import styled from "styled-components";

export const Container = styled.div`
    width: 2rem;
    height: 2rem;

    img {
        width: 100%;
        height: 100%;
    }
`;
