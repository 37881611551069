import styled from "styled-components";

export const Button = styled.button`
  background-color: var(--red-500);
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  color: var(--gray-50);
  border: none;
  border-radius: 0.25rem;
  width: 8rem;
  height: 2rem;
`;
