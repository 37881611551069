import styled from "styled-components";

export const DatalistLabel = styled.p`
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 1rem;
  color: var(--gray-700);
`;

export const SearchResidentForm = styled.form`
  text-align: center;
  margin-bottom: 1rem;
  border: 0.125rem solid var(--gray-900);
  border-radius: 0.25rem;
  padding: 0.5rem;
  background-color: var(--white);
  width: 100%;
`;

export const SearchTypeOptions = styled.div`
  display: flex;
  margin-bottom: 0.25rem;

  & > div:not(:last-of-type) {
    margin-right: 0.5rem;
  }
`;

export const SelectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div:not(:last-of-type) {
    margin-right: 1rem;
    width: calc(100% / 3 - 1rem);
  }

  & > div:last-of-type {
    width: calc(100% / 3);
  }

  & select {
    width: 100%;
  }

  &.hidden {
    display: none;
  }
`;

export const InputContainer = styled.div`
  & input {
    width: 100%;
  }

  &.hidden {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;

  button:not(:last-of-type) {
    margin-right: 0.5rem;
  }
`;

export const ResidentCardsContainer = styled.section`
  width: 100%;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media (max-width: 1350px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
