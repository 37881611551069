import { atom, selector } from "recoil";

const callIdsState = atom<string[]>({
  default: [],
  key: "CallIdsState",
});

const callIdsSelector = selector<string[]>({
  key: "CallIdsSelector",
  get: ({ get }) => {
    const state = get(callIdsState);

    return state;
  },
});

export default callIdsState;
export { callIdsSelector };
