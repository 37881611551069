import styled from "styled-components";

export const Card = styled.div`
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  width: 100%;
  border: 2px solid #000;
  width: calc(100% / 3 - 10px);
  max-height: 150px;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.5;
  }
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #000;
`;
