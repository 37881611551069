import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import Layout from "../../components/Layout";
import UserCard from "../../components/UserCard";
import { User } from "../../interfaces/User";
import { api } from "../../services/api";
import userState from "../../states/userState";
import { notify, ToastType } from "../../utils/toast";
import {
  Background,
  Container,
  EmployeeCards,
  EmployeeContainer,
  EmployeeTitle,
} from "./styles";

export default function Administration() {
  const user = useRecoilValue(userState);

  const [generalPorters, setGeneralPorters] = useState<User[]>([]);
  const [localPorters, setLocalPorters] = useState<User[]>([]);
  const [janitors, setJanitors] = useState<User[]>([]);
  const [aptManagers, setAptManagers] = useState<User[]>([]);

  const setEmployees = useCallback(
    (users: User[]) => {
      setGeneralPorters(
        users.filter((user) => user.permission === "generalPorter")
      );
      setLocalPorters(
        users.filter((user) => user.permission === "localPorter")
      );
      setJanitors(users.filter((user) => user.permission === "janitor"));
      setAptManagers(users.filter((user) => user.permission === "aptManager"));
    },
    [setGeneralPorters, setLocalPorters, setJanitors, setAptManagers]
  );

  const fetchEmployees = useCallback(
    async (endpoint: string) => {
      const response = await api.get(endpoint);

      const fetchedUsers = response.data.users;
      const filteredUsers = fetchedUsers.filter(
        (fetchedUser: User) => user.id !== fetchedUser.id
      );

      setEmployees(filteredUsers);
    },
    [setEmployees, user.id]
  );

  useEffect(() => {
    if (
      user.permission === "localPorter" ||
      user.permission === "generalPorter"
    ) {
      const endpoint =
        user.permission === "localPorter"
          ? `/user/administration/${user.residentialId}/${user.condominiumId}`
          : `/user/administration/${user.residentialId}`;

      try {
        fetchEmployees(endpoint);
      } catch (e) {
        notify(
          "Desculpe, ocorreu um erro ao tentarmos carregar as informações. Tente recarregar a página.",
          ToastType.error,
          5000
        );
      }
    }
  }, [fetchEmployees, user.condominiumId, user.permission, user.residentialId]);

  return (
    <Layout>
      <Container>
        <Background>
          <EmployeeContainer>
            <EmployeeTitle>Porteiros gerais</EmployeeTitle>
            <EmployeeCards>
              {generalPorters.length > 0 &&
                generalPorters.map((generalPorter) => (
                  <UserCard
                    key={generalPorter.id}
                    user={generalPorter}
                    isEmployee
                  />
                ))}
            </EmployeeCards>
          </EmployeeContainer>
          <EmployeeContainer>
            <EmployeeTitle>Porteiros locais</EmployeeTitle>
            <EmployeeCards>
              {localPorters.length > 0 &&
                localPorters.map((localPorter) => (
                  <UserCard
                    key={localPorter.id}
                    user={localPorter}
                    isEmployee
                  />
                ))}
            </EmployeeCards>
          </EmployeeContainer>
          <EmployeeContainer>
            <EmployeeTitle>Síndicos</EmployeeTitle>
            <EmployeeCards>
              {aptManagers.length > 0 &&
                aptManagers.map((aptManager) => (
                  <UserCard key={aptManager.id} user={aptManager} isEmployee />
                ))}
            </EmployeeCards>
          </EmployeeContainer>
          <EmployeeContainer>
            <EmployeeTitle>Zeladores</EmployeeTitle>
            <EmployeeCards>
              {janitors.length > 0 &&
                janitors.map((janitor) => (
                  <UserCard key={janitor.id} user={janitor} isEmployee />
                ))}
            </EmployeeCards>
          </EmployeeContainer>
        </Background>
      </Container>
    </Layout>
  );
}
