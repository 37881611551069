import { Avatar as StyledAvatar } from "./styles";
interface AvatarProps {
  src: string;
  alt: string;
  size?: string;
}

export default function Avatar({ src, alt, size = "" }: AvatarProps) {
  return (
    <StyledAvatar className={size}>
      <img src={src} alt={alt} />
    </StyledAvatar>
  );
}
