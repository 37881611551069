import styled from "styled-components";

export const Container = styled.div`
    color: var(--gray-50);
    background-color: var(--gray-400);
    text-align: center;
    margin: auto;
    font-size: 1rem;
    max-width: 6rem;
    padding: 0.25rem;
    margin-bottom: 0.5rem;
`;
