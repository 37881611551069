import styled from "styled-components";

export const Container = styled.nav`
  background-color: var(--white);
  display: flex;
  justify-content: center;
  width: 100%;

  & > div:not(:last-of-type) {
    border-right: 0.125rem solid var(--gray-300);
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  padding: 1rem;

  &.selected {
    background-color: var(--orange-500);

    a {
      color: var(--white);
    }
  }

  a {
    text-decoration: none;
    color: var(--gray-900);
    font-weight: bold;
    font-size: 1rem;
  }
`;
