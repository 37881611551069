const charSwitch = {
  "0": 6,
  "1": 7,
  "2": 8,
  "3": 9,
  "4": 1,
  "5": 2,
  "6": 3,
  "7": 4,
  "8": 5,
  "9": 0,
};

type Numbers = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";

const numberRegex = /\d/;

export function encrypt(text: string) {
  const encrypted = text
    .split("")
    .map((char) => {
      if (numberRegex.test(char)) return charSwitch[char as Numbers];

      return char;
    })
    .reverse()
    .join("");

  return encrypted;
}

export function decrypt(text: string) {
  const decrypted = text
    ? text
        .split("")
        .map((char) => {
          if (numberRegex.test(char)) {
            const entries = Object.entries(charSwitch);
            const realChar = (
              entries.find(([_, value]) => value === +char) as [string, number]
            )[0];

            return realChar;
          }

          return char;
        })
        .reverse()
        .join("")
    : "";

  return decrypted;
}
