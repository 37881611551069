import IntercomComponent from "../../components/IntercomComponent";
import Layout from "../../components/Layout";
import { Container } from "./styles";

function Intercom() {
  return (
    <Layout>
      <Container>
        <IntercomComponent />
      </Container>
    </Layout>
  );
}

export default Intercom;
