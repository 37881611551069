import { Bullet, CheckRadio, SearchTypeOption } from "./styles";

interface RadioOptionProps {
  onClick: () => void;
  id: string;
  defaultChecked?: boolean;
  selected: boolean;
  label: string;
}

export default function RadioOption({
  onClick,
  id,
  defaultChecked = false,
  selected,
  label,
}: RadioOptionProps) {
  return (
    <SearchTypeOption>
      <input
        type="radio"
        onClick={onClick}
        id={id}
        defaultChecked={defaultChecked}
      />
      <label htmlFor={id}>
        <CheckRadio className={selected ? "selected" : ""}>
          <Bullet />
        </CheckRadio>
        <span>{label}</span>
      </label>
    </SearchTypeOption>
  );
}
