import { ReactNode } from "react";
import AsideNotifications from "../AsideNotifications";
import MenuBar from "../MenuBar";
import { Container, Main } from "./styles";

interface LayoutProps {
    children: ReactNode;
}

export default function Layout({ children }: LayoutProps) {
    return (
        <Container>
            <MenuBar />
            <Main>{children}</Main>
            <AsideNotifications />
        </Container>
    );
}
