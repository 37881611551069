import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { api } from "../../services/api";
import userState from "../../states/userState";
import { notify, ToastType } from "../../utils/toast";
import CancelButton from "../CancelButton";
import ConfirmButton from "../ConfirmButton";
import DialogModal from "../DialogModal";
import {
  AutomationInfo,
  AutomationTextData,
  ButtonsContainer,
  Card,
} from "./styles";
import { Automation } from "../../interfaces/Automation";

interface AutomationCardProps {
  automation: Automation[];
  onClick?: () => void;
  size?: string;
}

export default function AutomationCard({
  automation,
  onClick,
  size = "md",
}: AutomationCardProps) {
  const [user] = useRecoilState(userState);

  const [isLoadingEntry, setIsLoadingEntry] = useState(false);
  const [isLoadingExit, setIsLoadingExit] = useState(false);

  const [isModalActive, setIsModalActive] = useState<boolean>(false);

  const [exit, setExit] = useState<Automation>({} as Automation);
  const [entry, setEntry] = useState<Automation>({} as Automation);
  const [currentPortId, setCurrentPortId] = useState<number>(0);

  const onConfirmOpenPort = useCallback(
    (id: number) => {
      try {
        api.post("/user_door_open", {
          userId: user.id,
          automationId: id,
        });

        notify("A porta foi aberta com sucesso!", ToastType.success);
      } catch (e) {
        notify(
          "Ops... ocorreu um erro ao tentar abrir a porta!",
          ToastType.error
        );
      }
    },
    [user.id]
  );

  useEffect(() => {
    const firstExit = automation.filter((item: any) => item.type === "OUT")[0];
    const firstEntry = automation.filter((item: any) => item.type === "IN")[0];
    const bothEntries = automation.filter(
      (item: any) => item.type === "BOTH"
    )[0];

    if (bothEntries) {
      setExit(bothEntries);
      setEntry(bothEntries);
    } else {
      setExit(firstExit);
      setEntry(firstEntry);
    }
  }, [automation]);

  return (
    <>
      <DialogModal
        active={isModalActive}
        title="Confirmação"
        ask={`Deseja abrir a porta ${automation[0]?.name}?`}
        confirmAction={() => {
          onConfirmOpenPort(currentPortId);

          setIsModalActive(false);
        }}
        cancelAction={() => {
          setIsModalActive(false);
        }}
        onClose={() => setIsModalActive(false)}
      />
      <Card onClick={onClick}>
        <AutomationInfo>
          <AutomationTextData>
            <h4>{automation[0].Door.name || ""}</h4>
            {automation[0].Door.condominium && (
              <p>Cond: {automation[0].Door.condominium.name || ""}</p>
            )}
          </AutomationTextData>
        </AutomationInfo>

        <ButtonsContainer>
          {entry && Object.values(entry).length > 0 && (
            <ConfirmButton
              title="Entrada"
              onClick={() => {
                setIsLoadingEntry(true);

                try {
                  setCurrentPortId(entry?.id);

                  setIsModalActive(true);
                } catch (e) {
                } finally {
                  setIsLoadingEntry(false);
                }
              }}
              loading={isLoadingEntry}
            />
          )}
          {exit && Object.values(exit).length > 0 && (
            <CancelButton
              title="Saída"
              onClick={() => {
                setIsLoadingExit(true);

                try {
                  setCurrentPortId(exit?.id);

                  setIsModalActive(true);
                } catch {
                } finally {
                  setIsLoadingExit(false);
                }
              }}
              loading={isLoadingExit}
            />
          )}
        </ButtonsContainer>
      </Card>
    </>
  );
}
