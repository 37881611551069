import { Link, To } from "react-router-dom";
import { Button as StyledButton } from "./styles";
import { BeatLoader } from "react-spinners";

interface ButtonProps {
  title: string | React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  active?: boolean;
  onClick?: () => void;
  size?: string;
  isLink?: boolean;
  href?: To;
}

function Button({
  active = false,
  disabled = false,
  loading = false,
  size = "sm",
  title,
  onClick = () => {},
  type = "button",
  isLink = false,
  href = "",
}: ButtonProps) {
  const activeClass = active ? "active" : "";

  if (isLink)
    return (
      <Link to={href}>
        <StyledButton
          disabled={disabled || loading}
          type={type}
          className={`${size} ${activeClass}`}
          onClick={() => onClick()}
        >
          {title}
        </StyledButton>
      </Link>
    );

  return (
    <StyledButton
      disabled={loading}
      type={type}
      className={`${size} ${activeClass}`}
      onClick={() => onClick()}
    >
      {loading ? <BeatLoader color="#fff" /> : title}
    </StyledButton>
  );
}

export default Button;
