import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--gray-50);
  background-color: #fff;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  border: 0.25rem solid #0000;
  transition: all 0.2s;
  color: var(--gray-200);
  font-size: 0.9rem;

  &.clickable {
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
`;

export const Image = styled.img`
  width: 65px;
  height: 65px;
`;

export const Info = styled.div`
  width: 100%;
  margin-left: 1rem;
  flex: 1;
`;

export const Title = styled.p`
  font-weight: bold;
  color: #6063a1;
`;

export const ContentContainer = styled.div<{ type: string }>`
  width: 100%;
  background-color: ${({ type }) =>
    type === "success"
      ? "#d1ffdd"
      : type === "warn"
      ? "#fffad1"
      : type === "error"
      ? "#ffd1d1"
      : "#d1e2ff"};
  padding: 0.5rem;
  margin-top: 0.25rem;
  border-radius: 0.25rem;
`;

export const Content = styled.p<{ type: string }>`
  color: ${({ type }) =>
    type === "success"
      ? "#3a3"
      : type === "warn"
      ? "#a93"
      : type === "error"
      ? "#a33"
      : "#33a"};
  font-weight: bold;
  word-break: break-word;
`;

export const TimeContainer = styled.p`
  width: 100%;
  margin-top: 0.75rem;
  font-size: 0.75rem;
  text-align: right;
`;
