import styled from "styled-components";

export const Container = styled.div`
  height: auto;
  border: 0.125rem solid var(--gray-900);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white);
  padding: 0.5rem;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    filter: brightness(0.92);
    border-color: var(--orange-500);
  }

  &:active {
    outline: 0.125rem solid var(--orange-200);
  }

  &.selected {
    background-color: var(--orange-500);

    h4 {
      color: var(--gray-50);
    }
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;

  figure {
    margin-right: 1rem;
  }
`;

export const UserTextData = styled.div`
  flex: 1;
  text-align: left;

  h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    // Addition lines for 2 line or multiline ellipsis
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-size: 1.125rem;
    color: var(--orange-500);
  }

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    // Addition lines for 2 line or multiline ellipsis
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-size: 0.9rem;

    span {
      font-weight: bold;
    }
  }

  &.md {
    width: 16rem;
  }

  &.lg {
    width: 24rem;
  }
`;

export const Message = styled.p`
  margin-top: 0.5rem;
  color: var(--gray-300);

  & > span {
    word-break: break-word;
  }

  &.selected {
    color: var(--gray-100);
  }
`;
