import styled from "styled-components";

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Container = styled.div`
  margin-top: 1rem;
  width: 100%;
  border: 2px solid #000;
  background-color: #fff;
  padding: 0.75rem;
  border-radius: 0.25rem;

  &.center {
    width: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  display: flex;

  & > div:not(:last-of-type) {
    margin-right: 1rem;
  }

  & > div {
    width: 100%;
  }
`;

export const ContainerTitle = styled.h1`
  text-align: center;
  margin-bottom: 0.5rem;
`;

export const RadioOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.25rem;

  & > div:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;

  & > button:not(:last-of-type) {
    margin-right: 0.5rem;
  }
`;

export const UserCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  overflow: auto;
  margin: 0.5rem;
  width: 100%;
`;

export const FormTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 0.5rem;
`;

export const PhotoContainer = styled.div`
  margin-right: 1.5rem;
  cursor: pointer;
  background-color: #eee;
  border: 0.125rem solid #000;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s opacity;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &:hover:not(.disabled) {
    opacity: 0.6;
  }
`;

export const FormTopButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > button:first-of-type {
    margin-bottom: 0.5rem;
  }
`;

export const FormContainer = styled.form``;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  & > div {
    margin: 0.25rem;
  }
`;

export const GuestCardsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1rem;

  & > div:not(:last-of-type) {
    margin-right: 0.5rem;
  }
`;

export const GuestCardsRow = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 3);

  & > div {
    margin-bottom: 0.5rem;
  }
`;

export const CarInputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 2fr) 0.5fr;

  & > div {
    margin: 0.25rem;
  }
`;

export const DeleteButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const DeleteButton = styled.button`
  background-color: #a22;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0.25rem;
  border: none;
`;

export const CheckboxContainer = styled.div`
  margin-top: 1rem;
  margin-left: 0.25rem;
`;

export const ReleaseAccessFormSubtitle = styled.p`
  font-size: 1rem;
`;

export const EntriesQuantityContainer = styled.div`
  margin-top: 1rem;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
`;

export const SearchGuestContainer = styled.div`
  margin-top: 1rem;
  text-align: center;

  & > div:first-of-type {
    margin-bottom: 0.5rem;
  }
`;
