import styled from "styled-components";

export const Container = styled.aside`
  box-shadow: -2px -6px 8px 0px #0004;
  overflow: auto;
`;

export const NotificationsContainer = styled.div`
  padding: 1rem 0.5rem;
`;
