import moment from "moment";

const setDateBoxes = (items: any[]) => {
  const today = moment().format("DD/MM/YYYY");
  const yesterday = moment().subtract(1, "days").format("DD/MM/YYYY");

  let currentDateBox = moment(items[0].createdAt).format("DD/MM/YYYY");

  return items.map((item, i) => {
    const itemDate = moment(item.createdAt).format("DD/MM/YYYY");

    const showDateBox = i === 0 || itemDate !== currentDateBox;

    if (showDateBox) {
      if (itemDate === today) item.dateBox = "Hoje";
      else if (itemDate === yesterday) item.dateBox = "Ontem";
      else item.dateBox = itemDate;

      currentDateBox = itemDate;
    } else item.dateBox = "";

    return item;
  });
};

export default setDateBoxes;
