import styled from "styled-components";

export const Menu = styled.aside`
  box-shadow: 2px -6px 8px 0px #0004;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

export const Navbar = styled.nav`
  border-top: 0.125rem solid var(--gray-300);
  padding: 1rem 0;

  ul {
    list-style-type: none;

    li:not(:last-of-type) {
      margin-bottom: 0.75rem;
    }
  }
`;

export const Profile = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h5 {
    margin: 0.5rem 0 1rem;
    font-size: 1.125rem;
  }

  p,
  ul {
    align-self: flex-start;
  }

  ul {
    margin-left: 2rem;
    padding-bottom: 1.5rem;
  }
`;

export const VersionInfo = styled.div`
  margin: 0.5rem;
  font-size: 0.5rem;
  text-align: center;
`;
