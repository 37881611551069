import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  ul {
    position: absolute;
    list-style-type: none;
    padding-bottom: 0.5rem;
    background-color: #fff;
    border: 0.125rem solid var(--gray-100);
    max-height: 300px;
    overflow: auto;

    li {
      cursor: pointer;
      padding: 0.5rem 0.75rem;
      transition: background-color 0.1s;

      &:hover {
        background-color: var(--orange-100);
      }
    }
  }
`;
