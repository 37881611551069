import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos, MdSearch } from "react-icons/md";
import { ActionTd, Container, Foot, SearchBox, TableContent } from "./styles";
import { useEffect, useState } from "react";
import ControlledInput from "../ControlledInput";
import moment from "moment";
import LoadingMessage from "../LoadingMessage";
import NotFoundMessage from "../NotFoundMessage";

type Item = { selected: number };

/* interface ColumnItem {
  title: string;
  key: string[];
  format?: "datetime" | "status";
} */

interface Action {
  icon: React.ReactNode;
  event: (item?: any) => void;
}

type Criteria = { key: string; value: any };
interface TableProps {
  titles: string[];
  rows: any[][];
  searchPlaceholder: string;
  searchState: [string, React.Dispatch<React.SetStateAction<string>>];
  count: number;
  onPageChange: (item: Item) => void;
  pageState: [number, React.Dispatch<React.SetStateAction<number>>];
  loading?: boolean;
}

export default function Table({
  titles,
  rows,
  searchPlaceholder = "",
  searchState,
  pageState,
  count,
  onPageChange,
  loading,
}: TableProps) {
  useEffect(() => {
    pageState[1](0);
  }, [searchState[0]]);

  return (
    <Container>
      <SearchBox>
        <ControlledInput
          placeholder={searchPlaceholder}
          className="table-search"
          value={searchState[0]}
          onChange={(event) => searchState[1](event.target.value)}
        />
        <MdSearch />
      </SearchBox>

      <TableContent>
        {!loading && rows.length > 0 ? (
          <>
            {titles.length > 0 && (
              <thead>
                <tr>
                  {titles.map((title) => (
                    <th>{title}</th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody>
              {rows.map((item) => {
                return (
                  <tr>
                    {item.map((cell) => (
                      <td>{cell}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </>
        ) : loading ? (
          <div style={{ padding: "2rem" }}>
            <LoadingMessage parentType="not-flex-parent" />
          </div>
        ) : (
          <div style={{ padding: "2rem" }}>
            <NotFoundMessage marginTop />
          </div>
        )}
      </TableContent>

      <Foot>
        {rows.length > 0 && (
          <ReactPaginate
            forcePage={pageState[0]}
            breakLabel="..."
            nextLabel={<MdArrowForwardIos />}
            onPageChange={(item) => {
              onPageChange(item);
              pageState[1](item.selected);
            }}
            pageRangeDisplayed={5}
            pageCount={count}
            previousLabel={<MdArrowBackIos />}
          />
        )}
      </Foot>
    </Container>
  );
}
