import moment from "moment";
import { ContentType } from ".";
import Button from "../../components/Button";
import Package from "../../interfaces/Package";
import { DetailCard, DetailsContainer, GapRow, Row } from "./styles";
import awsRoot from "../../utils/awsRoot";
import userState from "../../states/userState";
import { useRecoilValue } from "recoil";
import WebcamModal from "../../components/WebcamModal";
import { useState } from "react";
import { api } from "../../services/api";
import { notify, ToastType } from "../../utils/toast";
import DialogModal from "../../components/DialogModal";

type Props = {
  pack: Package;
  setSelectedPack: (value: any) => void;
  setPaths: React.Dispatch<React.SetStateAction<ContentType[]>>;
  setSelectedApartmentId: (value: any) => void;
  setSelectedResidentId: (value: any) => void;
};

export default function PackageDetails({
  pack,
  setSelectedPack,
  setPaths,
  setSelectedApartmentId,
  setSelectedResidentId,
}: Props) {
  const [openCamera, setOpenCamera] = useState(false);

  const [photo, setPhoto] = useState("");

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const user = useRecoilValue(userState);

  const towerLabel = user?.residential?.labelType !== "house" ? "Torre" : "Rua";
  const aptLabel =
    user?.residential?.labelType === "house"
      ? "Casa"
      : user?.residential?.labelType === "commercial"
      ? "Sala"
      : "Apt";

  const handleEditPackage = async () => {
    const data = {
      id: pack.id,
      isWithdrawImage: true,
      fileData: photo.split("base64,")[1],
    };

    await api.put("resident-package", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    });

    setPaths(() => ["list"]);
    setSelectedPack(null);

    notify("Encomenda retirada com sucesso", ToastType.success);
  };

  return (
    <div>
      <DialogModal
        active={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        ask="Deseja manter o destinatário atual da encomenda?"
        confirmAction={() => {
          setPaths((paths) => [...paths, "form"]);
        }}
        cancelAction={() => {
          setSelectedApartmentId(0);
          setSelectedResidentId(0);

          setPaths((paths) => [...paths, "select"]);
        }}
        confirmLabel="Sim"
        cancelLabel="Não"
      />
      {openCamera && (
        <WebcamModal
          setOpen={setOpenCamera}
          setUserImage={async (img: string) => setPhoto(img)}
          shouldVerifyUserImage={false}
        />
      )}
      <h1
        style={{
          textAlign: "center",
          marginBottom: "0.5rem",
        }}
      >
        Detalhes da encomenda
      </h1>
      <Button
        title="Voltar"
        onClick={() =>
          setPaths((paths) => {
            const auxArr = [...paths];
            auxArr.pop();

            return auxArr;
          })
        }
      />
      {photo && (
        <div
          style={{
            backgroundColor: "#fff",
            width: 400,
            margin: "auto",
            padding: 15,
            borderRadius: 10,
          }}
        >
          <p>
            <b>Foto da pessoa que retirou a encomenda: </b>
          </p>
          <div
            style={{
              padding: 15,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={photo} alt="" style={{ maxWidth: 200, maxHeight: 200 }} />
          </div>
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button title="Confirmar" onClick={handleEditPackage} />
            <Button
              title="Tirar outra foto"
              onClick={() => setOpenCamera(true)}
            />
          </div>
        </div>
      )}
      <DetailsContainer>
        {pack.status === "pending" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 10,
            }}
          >
            <Button
              title="Editar"
              size="sm"
              onClick={() => setIsConfirmationModalOpen(true)}
            />
            <Button
              title="Excluir"
              size="sm"
              onClick={async () => {
                // eslint-disable-next-line no-restricted-globals
                const answer = confirm("Deseja realmente excluir a encomenda?");

                if (answer) {
                  await api.delete(`resident-package/${pack.id}`);

                  setPaths(["list"]);
                  notify("Encomenda excluída!", ToastType.info);
                }
              }}
            />
          </div>
        )}
        <Row>
          <h3>{pack.title}</h3>
          <p style={{ color: pack.status === "done" ? "#00af00" : "#bda100" }}>
            <b>{pack.status === "done" ? "Entregue" : "Pendente"}</b>
          </p>
        </Row>
        <GapRow>
          <p>
            <b>Data: </b>
            {moment(pack.createdAt).format("DD/MM/YYYY")}
          </p>
          <p>
            <b>Hora: </b>
            {moment(pack.createdAt).format("HH:mm")}
          </p>
        </GapRow>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            margin: "5px 0",
          }}
        >
          <img
            src={`${awsRoot}resident_package/${pack.imageUUID}.jpg`}
            alt=""
            style={{ maxHeight: 400, maxWidth: 400 }}
          />
        </div>
        <p>
          <b>Observação: </b>
          <p>{pack.observation}</p>
        </p>
        {pack.resident && (
          <DetailCard style={{ display: "flex", gap: 10 }}>
            <div>
              <img
                src={`${awsRoot}users_images/${pack.resident.imageUUID}.jpg`}
                alt=""
                style={{ width: 70, height: 70 }}
              />
            </div>
            <div>
              <p>
                <b>{pack.resident.name + " " + pack.resident.surname}</b>
              </p>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {pack.resident.condominium && (
                  <p style={{ marginRight: 10 }}>
                    <b>Cond: </b>
                    {pack.resident.condominium.name}
                  </p>
                )}
                {pack.resident.tower && (
                  <p style={{ marginRight: 10 }}>
                    <b>{towerLabel}: </b>
                    {pack.resident.tower?.name}
                  </p>
                )}
                {pack.resident.apartment && (
                  <p style={{ marginRight: 10 }}>
                    <b>{aptLabel}: </b>
                    {pack.resident.apartment?.name}
                  </p>
                )}
              </div>
            </div>
          </DetailCard>
        )}
        {pack.createdByAdm && (
          <DetailCard
            style={{ display: "flex", flexDirection: "column", gap: 5 }}
          >
            <p>
              <b>Responsável pelo cadastro: </b>
              {pack.createdByAdm.name + " " + pack.createdByAdm.surname}
            </p>
            <img
              src={`${awsRoot}users_images/${pack.createdByAdm.imageUUID}.jpg`}
              alt=""
              style={{ width: 70, height: 70, alignSelf: "center" }}
            />
          </DetailCard>
        )}
        {pack.withdrawImageUUID && !pack.porterName && (
          <DetailCard
            style={{ display: "flex", flexDirection: "column", gap: 5 }}
          >
            <p>
              <b>Foto do responsável pela retirada</b>
            </p>
            <GapRow>
              <p>
                <b>Data: </b>
                {moment(pack.withdrawTime).format("DD/MM/YYYY")}
              </p>
              <p>
                <b>Hora: </b>
                {moment(pack.withdrawTime).format("HH:mm")}
              </p>
            </GapRow>
            <img
              src={`${awsRoot}resident_package/${pack.withdrawImageUUID}.jpg`}
              alt=""
              style={{ width: 70, height: 70, alignSelf: "center" }}
            />
          </DetailCard>
        )}
        {pack.withdrawImageUUID && pack.porterName && (
          <DetailCard>
            <p>Assinatura</p>
            <div>
              <GapRow>
                <p>
                  <b>Data: </b>
                  {moment(pack.withdrawTime).format("DD/MM/YYYY")}
                </p>
                <p>
                  <b>Hora: </b>
                  {moment(pack.withdrawTime).format("HH:mm")}
                </p>
              </GapRow>
              <p>
                <b>Responsável pela entrega informado pelo morador: </b>
                {pack.porterName}
              </p>
              <div
                style={{
                  borderBottom: "solid 1px #888",
                  width: "fit-content",
                }}
              >
                <img
                  src={`${awsRoot}resident_package/${pack.withdrawImageUUID}.jpg`}
                  alt=""
                  height={120}
                />
              </div>
            </div>
          </DetailCard>
        )}
        {pack.admDelivered && (
          <DetailCard
            style={{ display: "flex", flexDirection: "column", gap: 5 }}
          >
            <p>
              <b>Responsável pela entrega: </b>
              {pack.admDelivered.name + " " + pack.admDelivered.surname}
            </p>
            <img
              src={`${awsRoot}users_images/${pack.admDelivered.imageUUID}.jpg`}
              alt=""
              style={{ width: 70, height: 70, alignSelf: "center" }}
            />
          </DetailCard>
        )}
        {pack.status === "pending" && (
          <div
            style={{ display: "flex", justifyContent: "center", padding: 10 }}
          >
            <Button
              title="Retirar encomenda"
              size="lg"
              onClick={() => setOpenCamera(true)}
            />
          </div>
        )}
      </DetailsContainer>
    </div>
  );
}
