import { Link, useLocation } from "react-router-dom";
import { Item } from "./styles";

interface TabProps {
  title: string;
  path: string;
}

export default function Tab({ title, path }: TabProps) {
  const { pathname } = useLocation();
  //const lastSlashIndex = pathname.lastIndexOf("/");
  const selectedClass =
    pathname /* .substring(lastSlashIndex) */ === path ? "selected" : "";

  return (
    <Item className={selectedClass}>
      <Link to={path}>{title}</Link>
    </Item>
  );
}
