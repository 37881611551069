import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  label {
    text-align: left;
    font-weight: bold;
    font-size: 1rem;
    color: var(--gray-700);
  }

  select {
    background-color: var(--white);
    color: var(--orange-500);
    font-weight: bold;
    font-size: 1rem;
    height: 2rem;
    border: 0.125rem solid var(--gray-900);
    border-radius: 0.25rem;

    &.md {
      min-width: 8rem;
    }

    &.lg {
      min-width: 12rem;
    }

    &.xl {
      min-width: 18rem;
    }

    &:focus {
      outline: 0.125rem solid var(--orange-200);
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }
`;
