import styled from "styled-components";

export const Button = styled.button`
  /* display: flex;
  align-items: center;
  justify-content: center; */
  text-align: center;
  text-transform: uppercase;
  background-color: var(--orange-500);
  border: none;
  border-radius: 0.25rem;
  color: var(--gray-50);
  font-size: 1rem;
  font-weight: bold;
  transition: 0.2s opacity;

  &.active {
    background-color: var(--gray-300);
  }

  &:hover:not([disabled]) {
    opacity: 0.8;
  }

  &.xs {
    width: 2rem;
    height: 1.5rem;
    line-height: 0.75rem;
  }

  &.sm {
    width: 7rem;
    height: 2rem;
    line-height: 1rem;
  }

  &.md {
    width: 9rem;
    height: 2.25rem;
    line-height: 1rem;
  }

  &.lg {
    width: 12rem;
    height: 2.5rem;
    line-height: 1.125rem;
  }

  &.xl {
    width: 14rem;
    height: 3rem;
    line-height: 1.5rem;
  }

  &[disabled] {
    background-color: var(--orange-50);
    cursor: not-allowed;
  }
`;
