import React from "react";
import ControlledInput from "../ControlledInput";
import { Container } from "./styles";

export interface Item {
  key: string;
  value: string;
  onClick?: () => void;
}

interface Props {
  label?: string;
  placeholder?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  selectValue: (value: string) => void;
  selectKey?: (key: string) => void;
  list: Item[];
}

export default function Datalist({
  label,
  placeholder,
  value,
  onChange,
  selectValue,
  selectKey,
  list,
}: Props) {
  return (
    <Container>
      <ControlledInput
        label={label ?? ""}
        placeholder={placeholder ?? ""}
        value={value}
        onChange={onChange}
      />
      {list.length > 0 && (
        <ul>
          {list.map((item) => {
            return (
              <li
                onClick={() => {
                  if (selectKey) selectKey(item.key);

                  selectValue(item.value);
                }}
                key={item.key}
              >
                {item.value}
              </li>
            );
          })}
        </ul>
      )}
    </Container>
  );
}
