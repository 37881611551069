import styled from "styled-components";

export const StyledCheckbox = styled.div`
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
  border: 0.125rem solid;
  display: flex;
  align-items: center;
  justify-content: center;
`;
