import { ChangeEvent, useEffect, useState } from "react";
import ControlledInput from "../../components/ControlledInput";
import Package from "../../interfaces/Package";
import { PhotoInput, TextArea } from "./styles";
import { FaCamera } from "react-icons/fa";
import Button from "../../components/Button";
import { ContentType } from ".";
import WebcamModal from "../../components/WebcamModal";
import { api } from "../../services/api";
import { useRecoilValue } from "recoil";
import userState from "../../states/userState";
import { notify, ToastType } from "../../utils/toast";
import awsRoot from "../../utils/awsRoot";

type Props = {
  setPaths: React.Dispatch<React.SetStateAction<ContentType[]>>;
  setSelectedPack: (value: any) => void;
  selectedPack: Package;
  apartmentId: number;
  residentId: number;
};

export default function PackageForm({
  setPaths,
  setSelectedPack,
  selectedPack,
  apartmentId,
  residentId,
}: Props) {
  const user = useRecoilValue(userState);

  const [fileData, setFileData] = useState("");
  const [title, setTitle] = useState("");
  const [observation, setObservation] = useState("");

  const [openCamera, setOpenCamera] = useState(false);

  const handleCreatePackage = async () => {
    if (!fileData) {
      alert("Insira uma foto para a encomenda!");
      return;
    }

    if (!title) {
      alert("Insira um código de identificação!");
      return;
    }

    if (residentId === 0 && !observation) {
      alert(
        "O nome do destinatário deve ser inserido no campo observação pois nenhum morador foi selecionado!"
      );
      return;
    }

    const data = {
      title: title,
      createdByAdmId: user.id,
      apartmentId: apartmentId !== 0 ? apartmentId : undefined,
      residentId: residentId !== 0 ? residentId : undefined,
      fileData: fileData.split("base64,")[1],
      observation,
    };
    try {
      await api.post("resident-package", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });

      setPaths(["list"]);
      setSelectedPack(null);

      notify("Encomenda cadastrada com sucesso!", ToastType.success);
    } catch (e) {
      console.log(e, data);
    }
  };

  const handleEditPackage = async () => {
    const data: any = {
      id: selectedPack.id,
      title,
      apartmentId: apartmentId !== 0 ? apartmentId : selectedPack.apartmentId,
      residentId:
        apartmentId !== 0 && residentId !== 0
          ? residentId
          : apartmentId !== 0 && residentId === 0
          ? undefined
          : selectedPack.residentId,
      observation,
    };
    if (fileData) data.fileData = fileData.split("base64,")[1];

    await api.put("resident-package", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    });

    setPaths(["list"]);
    setSelectedPack(null);

    notify("Encomenda cadastrada com sucesso!", ToastType.success);
  };

  useEffect(() => {
    if (selectedPack) {
      setFileData(`${awsRoot}resident_package/${selectedPack.imageUUID}.jpg`);
      setTitle(selectedPack.title);
      if (selectedPack.observation) setObservation(selectedPack.observation);
    }
  }, [selectedPack]);

  return (
    <div>
      {openCamera && (
        <WebcamModal
          setOpen={setOpenCamera}
          setUserImage={async (img: string) => setFileData(img)}
          shouldVerifyUserImage={false}
        />
      )}
      <h1
        style={{
          textAlign: "center",
          marginBottom: "0.5rem",
        }}
      >
        {selectedPack ? "Editar" : "Cadastrar"} encomenda
      </h1>
      <div>
        <Button
          title="Voltar"
          onClick={() =>
            setPaths((paths) => {
              const auxArr = [...paths];
              auxArr.pop();

              return auxArr;
            })
          }
        />
      </div>
      <PhotoInput onClick={() => setOpenCamera(true)}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {fileData && fileData.length ? (
            <img
              src={fileData}
              alt=""
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          ) : (
            <>
              <FaCamera size={40} />
              <p>Foto da encomenda</p>
            </>
          )}
        </div>
      </PhotoInput>
      <ControlledInput
        placeholder="Código de identificação"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        maxLength={45}
      />
      <div
        style={{
          marginTop: 10,
          border: "2px solid #000",
          borderRadius: 5,
          overflow: "hidden",
          backgroundColor: "#fff",
          padding: 5,
        }}
      >
        <TextArea
          maxLength={100}
          placeholder="Observação"
          name=""
          id=""
          onChange={(e) => setObservation(e.target.value)}
        />
        <p style={{ textAlign: "end" }}>{observation.length} de 100</p>
      </div>
      <div style={{ marginTop: 10, display: "flex", justifyContent: "center" }}>
        <Button
          title={selectedPack ? "Salvar" : "Cadastrar"}
          onClick={selectedPack ? handleEditPackage : handleCreatePackage}
        />
      </div>
    </div>
  );
}
