import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { MdCameraAlt } from "react-icons/md";
import { useRecoilState } from "recoil";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import ControlledInput from "../../../components/ControlledInput";
import Layout from "../../../components/Layout";
import RadioOption from "../../../components/RadioOption";
import RegisterPeopleUserCard from "../../../components/RegisterPeopleCards/RegisterPeopleUserCard";
import Select, { Option } from "../../../components/Select";
import WebcamModal from "../../../components/WebcamModal";
import { Apartment } from "../../../interfaces/Apartment";
import { Condominium } from "../../../interfaces/Condominium";
import { Door } from "../../../interfaces/Door";
import { Tower } from "../../../interfaces/Tower";
import { Permission, User } from "../../../interfaces/User";
import { api } from "../../../services/api";
import photoValidationState from "../../../states/photoValidationState";
import userState from "../../../states/userState";
import isValidCpf from "../../../utils/isCpfValid";

import _ from "lodash";
import Datalist from "../../../components/Datalist";
import DialogModal from "../../../components/DialogModal";
import Company from "../../../interfaces/Company";
import useStartCall from "../../../utils/startCall";
import { notify, ToastType } from "../../../utils/toast";
import {
  ButtonsContainer,
  CheckboxContainer,
  Container,
  ContainerTitle,
  EntriesQuantityContainer,
  FormContainer,
  FormTopContainer,
  InputsContainer,
  Main,
  PhotoContainer,
  RadioOptionsContainer,
  ReleaseAccessFormSubtitle,
  SelectContainer,
  UserCardsContainer,
} from "../styles";
import getCompany from "../../../utils/getCompany";
import awsRoot from "../../../utils/awsRoot";

type RegisterGuestResidentSearch = {
  condominiumId: string;
  towerId: string;
  apartmentId: string;
  resident: string;
  company: string;
} | null;

type GuestType =
  | ""
  | "condominiumService"
  | "guest"
  | "apartmentService"
  | "deliveryman"
  | "appDriver";

type RegisterGuestData = {
  createdAt?: string;
  userId?: number;
  users?: User[];
  id?: number;
  ids?: number[];
  image?: string;
  imageUUID?: string;
  name: string;
  cpf: string;
  rg: string;
  phone?: string;
  type: GuestType;
  vehicle: boolean;
  carModel: string;
  carPlate: string;
  carColor: string;
  isImageVerify: boolean;
  updatedAt?: string;
  uuid: string;
};

type ReleaseGuestForm = {
  initialTime: string;
  expirationTime: string;
  initialDate: string;
  expirationDate: string;
  usesLeft: number;
  doorsId: number[];
};

type ReleaseGuestData = {
  initialTime: Date;
  expirationTime: Date;
  residentialId: number;
  guestRegisterId: number;
  usesLeft: number;
  doorsId: number[];
};

const initialRegisterGuestData: RegisterGuestData = {
  userId: 0,
  users: [],
  ids: [],
  image: "",
  name: "",
  cpf: "",
  rg: "",
  type: "",
  vehicle: false,
  carModel: "",
  carPlate: "",
  carColor: "",
  isImageVerify: false,
  uuid: "",
};

export default function RegisterGuest() {
  moment.locale("pt-br");

  const [user] = useRecoilState(userState);

  const initialReleaseGuestForm: ReleaseGuestForm = {
    initialTime: moment().locale("pt-br").format("HH:mm"),
    expirationTime: moment().add(1, "hour").locale("pt-br").format("HH:mm"),
    initialDate: moment().format("YYYY-MM-DD"),
    expirationDate: moment().format("YYYY-MM-DD"),
    usesLeft: 1,
    doorsId: [],
  };

  const startCall = useStartCall();

  const residentLabel =
    user?.residential?.labelType !== "commercial"
      ? "Morador"
      : "Pessoa interna";
  const guestLabel =
    user?.residential?.labelType !== "commercial"
      ? "Visitante"
      : "Pessoa externa";
  const towerLabel = user?.residential?.labelType !== "house" ? "Torre" : "Rua";
  const apartmentLabel =
    user?.residential?.labelType === "house"
      ? "Casa"
      : user?.residential?.labelType === "commercial"
      ? "Sala"
      : "Apartamento";

  const [isLoadingCall, setIsLoadingCall] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isLoadingSelectResident, setIsLoadingSelectResident] = useState(false);

  const [openCamera, setOpenCamera] = useState(false);
  const [photoValidation, setPhotoValidation] =
    useRecoilState(photoValidationState);

  const [currentStep, setCurrentStep] = useState<
    "select-guest" | "select-resident" | "release-access"
  >("select-guest");
  const [selectGuestStep, setSelectGuestStep] = useState<
    "select-document" | "select-guest"
  >("select-document");

  const [occupiedApartmentsList, setOccupiedApartmentsList] = useState<
    Apartment[]
  >([]);

  const [searchList, setSearchList] = useState<string[]>([]);

  const [initialRg, setInitialRg] = useState("");
  const [initialCpf, setInitialCpf] = useState("");

  const [towersList, setTowersList] = useState<Tower[]>([]);
  const [condominiaList, setCondominiaList] = useState<Condominium[]>([]);
  const [residentList, setResidentList] = useState<User[]>([]);
  const [companiesList, setCompaniesList] = useState<Company[]>([]);
  const [doorList, setDoorList] = useState<Door[]>([]);

  const [companyApartmentId, setCompanyApartmentId] = useState(0);

  const [loadingReleaseAccess, setLoadingReleaseAccess] = useState(false);

  const personTypeList: Option[] = [
    { id: "guest", name: guestLabel },
    { id: "condominiumService", name: "Prestador de serviço de condomínio" },
    {
      id: "apartmentService",
      name: `Prestador de serviço de ${apartmentLabel.toLocaleLowerCase()}`,
    },
    { id: "deliveryman", name: "Entregador" },
    { id: "appDriver", name: "Motorista de aplicativo" },
  ];

  const [registerGuestResidentSelected, setRegisterGuestResidentSelected] =
    useState<User | null>(null);
  const [registerGuestResidents, setRegisterGuestResidents] = useState<User[]>(
    []
  );

  const [registerGuestResidentSearch, setRegisterGuestResidentSearch] =
    useState<RegisterGuestResidentSearch>(null);

  const [selectedGuestData, setSelectedGuestData] = useState<RegisterGuestData>(
    initialRegisterGuestData
  );
  const [isNewGuest, setIsNewGuest] = useState(false);
  const [searchingGuestType, setSearchingGuestType] = useState<
    "rg" | "cpf" | "name"
  >("rg");

  const [selectResidentStep, setSelectResidentStep] = useState<
    "type" | "selection"
  >("type");
  const [selectedResidentType, setSelectedResidentType] = useState<
    "not-linked" | "linked"
  >("linked");
  const [selectableResidentsList, setSelectableResidentsList] = useState<
    User[]
  >([]);

  const [releaseGuestForm, setReleaseGuestForm] = useState<ReleaseGuestForm>(
    initialReleaseGuestForm
  );
  const [isGuestConfirmationModalOpen, setIsGuestConfirmationModalOpen] =
    useState(false);
  const [guestConfirmationText, setGuestConfirmationText] = useState("");

  const fetchResidents = useCallback(
    async (resident: string) => {
      const response = await api.get(
        `user/searchByName/${resident}/${user.residentialId}`
      );

      return response.data.users;
    },
    [user.residentialId]
  );

  const searchResidentsByApartment = useCallback(
    (apartmentId: number | string) => {
      api
        .get(`user/apartment/${apartmentId}`)
        .then((response) => response.data.users)
        .then((residents) => setRegisterGuestResidents(residents));
    },
    []
  );

  const searchResidentsByName = useCallback(
    async (name: string) => {
      const residents = await fetchResidents(name);
      setRegisterGuestResidents(residents);
    },
    [fetchResidents]
  );

  const handleIsDataValid = useCallback(() => {
    const data = { ...selectedGuestData };

    const errors: string[] = [];

    if (Object.values(data).length === 0)
      errors.push("Preencha os campos do formulário");

    if (!data.image && !data.imageUUID) errors.push("A foto é obrigatória");
    else if (!data.isImageVerify && user.residential.shouldVerifyUserImage)
      errors.push("A foto deve que ser verificada");

    if (!data.name || data.name?.length < 2) errors.push("Nome inválido");
    if (
      data.phone &&
      !(
        data.phone.replace(/[-()\s]/g, "").length == 11 ||
        data.phone.replace(/[-()\s]/g, "").length == 10
      )
    )
      errors.push("Telefone inválido");
    if (!data.cpf && !data.rg) errors.push("Informe um RG ou CPF");
    if (data.cpf && !isValidCpf(data.cpf.replace(/[.-]/g, "")))
      errors.push("CPF inválido");
    if (data.rg && (data.rg.length < 7 || data.rg.length > 9))
      errors.push("RG inválido");
    if (!data.type || data.type?.length === 0)
      errors.push("Selecione um tipo de pessoa");
    if (data.vehicle) {
      if (!data.carModel || data.carModel?.length < 2)
        errors.push("Modelo de carro inválido");

      data.carPlate = data.carPlate?.replace(/[-\s]/, "");
      if (
        !data.carPlate ||
        (!/[a-zA-Z]{3}\d{4}/.test(data.carPlate) &&
          !/[a-zA-Z]{3}\d[a-zA-Z]\d{2}/.test(data.carPlate))
      )
        errors.push("Placa de carro inválida");

      if (!data.carColor || data.carColor?.length < 3)
        errors.push("Cor de carro inválida");
    }

    if (errors.length > 0) {
      const error = errors.find((error) => error);
      return error && notify(error, ToastType.error, 3000);
    }

    return true;
  }, [selectedGuestData]);

  const registerGuest = useCallback(
    async (userId: number) => {
      const data = { ...selectedGuestData };

      delete data.ids;
      delete data.users;

      if (data.cpf) data.cpf = data.cpf.replace(/[.-]/g, "");
      if (data.phone) data.phone = data.phone.replace(/[\s()-]/g, "");

      const formData = new FormData();

      if (data.image) {
        const commaIndex = data.image.indexOf(",");
        formData.append("fileData", data.image.substring(commaIndex + 1));
      }

      formData.append("userId", String(userId));
      delete data.userId;

      Object.entries(data).forEach((input) => {
        const [key, value] = input;
        if (key !== "image" && value !== null && value)
          formData.append(key, String(value));
      });
      if (!formData.get("isUpdatedByVisitor"))
        formData.append("isUpdatedByVisitor", "true");

      try {
        const response = await api.post("/guest_register/porterWeb", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });

        const registeredUser = response.data;
        setSelectedGuestData(registeredUser);
      } catch (e) {
        console.log(e);
      }
    },
    [selectedGuestData]
  );

  const updateGuest = useCallback(
    async (userId: number) => {
      const data = { ...selectedGuestData };

      const isNewLink = !_.uniqBy(data.users, (item) => item.id).some(
        (item) => item.id === userId
      );

      if (isNewLink && userId) {
        await registerGuest(userId);
        return;
      } else {
        const index = _.uniqBy(data.users, (user) => user.id).findIndex(
          (item) => item.id === userId
        );
        const guestId = _.uniq(data.ids)[index];

        data.id = guestId;
      }

      if (data.cpf) data.cpf = data.cpf.replace(/[.-]/g, "");

      delete data.createdAt;
      delete data.updatedAt;
      delete data.ids;
      delete data.users;

      const booleans = ["vehicle"];

      const formData = new FormData();

      if (data.image) {
        const commaIndex = data.image.indexOf(",");
        if (commaIndex !== -1)
          formData.append("fileData", data.image.substring(commaIndex + 1));

        delete data.imageUUID;
      }

      Object.entries(data).forEach((input) => {
        const [key, value] = input;

        if (key !== "userId" && value !== null)
          if (!booleans.includes(key))
            formData.append(key, String(value ?? ""));
          else formData.append(key, String(value ?? false));
      });

      await api.post("/guest_register/updateByPorterWeb", formData);

      setSelectedGuestData({ ...selectedGuestData, id: data.id });
    },
    [selectedGuestData, registerGuest]
  );

  const releaseAccess: React.FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault();

      setLoadingReleaseAccess(true);

      const initialDateParts = releaseGuestForm.initialDate.split("-");
      const initialTimeParts = releaseGuestForm.initialTime.split(":");

      const expirationDateParts = releaseGuestForm.expirationDate.split("-");
      const expirationTimeParts = releaseGuestForm.expirationTime.split(":");

      const releaseGuestData: ReleaseGuestData = {
        initialTime: new Date(
          +initialDateParts[0],
          +initialDateParts[1] - 1,
          +initialDateParts[2],
          +initialTimeParts[0],
          +initialTimeParts[1]
        ),
        expirationTime: new Date(
          +expirationDateParts[0],
          +expirationDateParts[1] - 1,
          +expirationDateParts[2],
          +expirationTimeParts[0],
          +expirationTimeParts[1]
        ),
        doorsId: releaseGuestForm.doorsId,
        guestRegisterId: selectedGuestData.id ?? 0,
        residentialId: registerGuestResidentSelected?.id ?? user.id,
        usesLeft: releaseGuestForm.usesLeft,
      };

      const errors: string[] = [];

      if (releaseGuestData.doorsId.length <= 0)
        errors.push("Selecione ao menos uma porta");
      if (releaseGuestData.expirationTime <= releaseGuestData.initialTime)
        errors.push(
          "Data e hora inicial devem ser menores do data e hora de expiração respectivamente"
        );
      if (releaseGuestData.usesLeft <= 0)
        errors.push("O mínimo de entradas permitidas deve ser 1");

      if (errors.length > 0) {
        setLoadingReleaseAccess(false);

        const error = errors.find((error) => error);
        return error && notify(error, ToastType.error, 3000);
      }

      try {
        const res = await api.post("/password", {
          ...releaseGuestData,
          status: "accepted",
        });

        setReleaseGuestForm({
          ...initialReleaseGuestForm,
          doorsId: [],
        });

        setTimeout(
          () =>
            notify(
              `${guestLabel} cadastrado e senha de acesso gerada com sucesso!`,
              ToastType.success,
              3000
            ),
          500
        );

        clean();

        setCurrentStep("select-guest");
      } catch (err) {
        console.log(err);
        setTimeout(
          () => notify("Ops, algo de errado aconteceu!", ToastType.error, 3000),
          500
        );
      } finally {
        setLoadingReleaseAccess(false);
      }
    },
    [
      guestLabel,
      initialReleaseGuestForm,
      registerGuestResidentSelected?.id,
      releaseGuestForm.doorsId,
      releaseGuestForm.expirationDate,
      releaseGuestForm.expirationTime,
      releaseGuestForm.initialDate,
      releaseGuestForm.initialTime,
      releaseGuestForm.usesLeft,
      selectedGuestData.id,
      user.id,
    ]
  );

  const fetchApartments = useCallback(async (endpoint: string) => {
    const response = await api.get(endpoint);
    const apartments: Apartment[] = response.data.apartments;

    const apartmentsOptions =
      apartments.length > 0
        ? apartments
            .map((apartment) => ({
              id: apartment.id,
              name: apartment.name,
            }))
            .filter((item: any) => item.name !== "Interfone")
        : [];

    return apartmentsOptions;
  }, []);

  const fetchDoors = useCallback(
    async (residentialId: number, condominiumId?: number) => {
      const endpoint = `/door/guest?residentialId=${residentialId}${
        condominiumId ? "&condominiumId=" + condominiumId : ""
      }`;

      const response = await api.get(endpoint);

      const doors: Door[] = response.data;
      setDoorList(doors);
    },
    []
  );

  const fetchCondominia = useCallback(async (residentialId: number) => {
    const endpoint = `residential/getCondominia/${residentialId}`;

    try {
      const response = await api.get(endpoint);

      const condominia: Condominium[] = response.data.condominia;
      const condominiumOptions = condominia
        .map((condominium) => ({
          id: condominium.id,
          name: condominium.name,
        }))
        .filter((condominium) => condominium.name !== "Interfone");

      return condominiumOptions;
    } catch (err) {
      console.log(err);
    }
  }, []);
  const fetchTowers = useCallback(async (condominiumId: number) => {
    const endpoint = `condominium/getTowers/${condominiumId}`;

    try {
      const response = await api.get(endpoint);

      const towers: Condominium[] = response.data.towers;
      const condominiumOptions = towers
        .map((tower) => ({
          id: tower.id,
          name: tower.name,
        }))
        .filter((tower) => tower.name !== "Interfone");

      return condominiumOptions;
    } catch (err) {
      console.log(err);
    }
  }, []);
  const fetchInitialRegisterGuestResidentSelectOptions =
    useCallback(async () => {
      if (user.permission === "generalPorter") {
        if (user.residentialId) {
          const condominia = await fetchCondominia(user.residentialId);
          if (condominia) setCondominiaList(condominia);
        }
      } else {
        if (user.condominiumId) {
          const towers = await fetchTowers(user.condominiumId);
          if (towers) setTowersList(towers);
        }
      }
    }, [
      fetchCondominia,
      fetchTowers,
      user.condominiumId,
      user.permission,
      user.residentialId,
    ]);

  const clean = () => {
    setRegisterGuestResidentSelected(null);
    setSearchList([]);
    setSelectGuestStep("select-document");
    setSelectedGuestData({} as RegisterGuestData);
    setSelectResidentStep("type");
    setSelectedResidentType("linked");
    setRegisterGuestResidents([]);
    setSelectableResidentsList([]);
    setRegisterGuestResidentSearch(null);
  };

  const linkGuestToResident = async () => {
    const id = registerGuestResidentSelected?.id ?? user.id;

    try {
      if (isNewGuest) await registerGuest(id);
      else await updateGuest(id);

      setCurrentStep("release-access");
    } catch (e) {
      console.log(e);
      notify("Ops... Erro ao vincular visitante ao morador!", ToastType.error);
    }
  };

  const handleSetResidents = async () => {
    const isCommercialResidential =
      user.residential?.labelType === "commercial";

    const list: User[] = [];

    if (selectedResidentType === "linked" && selectedGuestData.users) {
      const residents =
        user.permission === Permission.GeneralPorter
          ? selectedGuestData.users
          : selectedGuestData.users.filter(
              (item) => item.condominiumId === user.condominiumId
            );

      if (isCommercialResidential) {
        for (let i = 0; i < residents.length; i++) {
          const company = await getCompany(
            residents[i].residentialId,
            residents[i].condominiumId as number,
            residents[i].towerId as number,
            residents[i].apartmentId as number
          );

          list.push({ ...residents[i], company });
        }
      } else list.push(...residents);

      setSelectableResidentsList(list);
    } else if (
      selectedResidentType === "not-linked" &&
      registerGuestResidents
    ) {
      const residents =
        user.permission === Permission.GeneralPorter
          ? registerGuestResidents
          : registerGuestResidents.filter(
              (item) => item.condominiumId === user.condominiumId
            );

      if (isCommercialResidential) {
        for (let i = 0; i < residents.length; i++) {
          const company = await getCompany(
            residents[i].residentialId,
            residents[i].condominiumId as number,
            residents[i].towerId as number,
            residents[i].apartmentId as number
          );

          list.push({ ...residents[i], company });
        }
      } else list.push(...residents);

      setSelectableResidentsList(list);
    }
  };

  useEffect(() => {
    if (registerGuestResidentSearch)
      if (registerGuestResidentSearch.condominiumId) {
        fetchTowers(+registerGuestResidentSearch.condominiumId).then(
          (towers) => {
            if (towers) setTowersList(towers);
          }
        );
      } else {
        setRegisterGuestResidentSearch({
          ...registerGuestResidentSearch,
          towerId: "",
          apartmentId: "",
        });
        setTowersList([]);
        setOccupiedApartmentsList([]);
      }
  }, [registerGuestResidentSearch?.condominiumId]);

  useEffect(() => {
    if (registerGuestResidentSearch)
      if (registerGuestResidentSearch.towerId !== "") {
        fetchApartments(
          `tower/getApartmentsWithUsers/${registerGuestResidentSearch.towerId}`
        )
          .then((apartments) => setOccupiedApartmentsList(apartments))
          .catch((err) => console.log(err));
      } else {
        setRegisterGuestResidentSearch({
          ...registerGuestResidentSearch,
          apartmentId: "",
        });
        setOccupiedApartmentsList([]);
      }
  }, [registerGuestResidentSearch?.towerId]);

  useEffect(() => {
    const resident = registerGuestResidentSearch?.resident || "";

    if (resident) {
      if (resident)
        fetchResidents(resident).then((residents: User[]) => {
          const username =
            residents.length > 0
              ? residents[0].name + " " + residents[0].surname
              : "";

          if (
            residents.length === 0 ||
            (residents.length === 1 && username === resident)
          )
            setResidentList([]);
          else setResidentList(residents);
        });
    } else setResidentList([]);
  }, [registerGuestResidentSearch?.resident]);

  useEffect(() => {
    if (registerGuestResidentSelected) {
      if (registerGuestResidentSelected)
        fetchDoors(
          registerGuestResidentSelected?.residentialId,
          registerGuestResidentSelected?.condominiumId
        );
    } else fetchDoors(user.residentialId, user?.condominiumId);
  }, [registerGuestResidentSelected, user.residentialId]);

  useEffect(() => {
    if (currentStep === "select-resident")
      fetchInitialRegisterGuestResidentSelectOptions();
    else if (currentStep === "release-access") {
      setReleaseGuestForm({
        ...releaseGuestForm,
        initialDate: moment().format("YYYY-MM-DD"),
        expirationDate: moment().format("YYYY-MM-DD"),
        initialTime: moment().locale("pt-br").format("HH:mm"),
        expirationTime:
          user?.residential?.labelType === "commercial"
            ? "23:59"
            : moment().add(1, "hour").locale("pt-br").format("HH:mm"),
      });
    }
  }, [currentStep]);

  useEffect(() => {
    handleSetResidents();
  }, [
    selectedResidentType,
    selectedGuestData,
    registerGuestResidents,
    user.permission,
    user.condominiumId,
    selectResidentStep,
  ]);

  useEffect(() => {
    if (photoValidation.isValid === "true") {
      notify("Foto validada com sucesso!", ToastType.success);
      setSelectedGuestData((prev) => ({
        ...prev,
        isImageVerify: true,
      }));
    } else if (photoValidation.isValid === "false") {
      notify("Foto inválida: Tire outra foto!", ToastType.error);
      setSelectedGuestData((prev) => ({ ...prev, image: undefined }));
    } else if (photoValidation.isValid === "noPhoto") return;

    setPhotoValidation({ isValid: "noPhoto" });
  }, [photoValidation.isValid]);

  useEffect(() => {
    if (
      towersList.length === 1 &&
      selectResidentStep === "selection" &&
      selectedResidentType === "not-linked"
    ) {
      if (registerGuestResidentSearch)
        setRegisterGuestResidentSearch({
          ...registerGuestResidentSearch,
          towerId: String(towersList[0].id),
        });
      else
        setRegisterGuestResidentSearch({
          towerId: String(towersList[0].id),
          apartmentId: "",
          resident: "",
          company: "",
        } as RegisterGuestResidentSearch);
    }
  }, [towersList, selectedResidentType, selectResidentStep]);

  useEffect(() => {
    if (
      registerGuestResidentSearch &&
      registerGuestResidentSearch.company.length > 0
    ) {
      const residentialId = user.residentialId ?? 0;
      const condominiumId = user.condominiumId ?? 0;

      api
        .get(
          `/companies/all/10/0/${residentialId}/${condominiumId}/0/0/${registerGuestResidentSearch?.company}`
        )
        .then((res) => res.data)
        .then((data) => {
          const companies: Company[] = data.rows;

          setCompaniesList(companies);
        });
    } else setCompaniesList([]);
  }, [registerGuestResidentSearch?.company]);

  useEffect(() => {
    if (selectedGuestData.cpf)
      setInitialCpf(selectedGuestData.cpf.replace(/.-/g, ""));
    if (selectedGuestData.rg)
      setInitialRg(selectedGuestData.rg.replace(/.-/g, ""));
  }, [selectedGuestData.cpf, selectedGuestData.rg]);

  return (
    <Layout>
      {openCamera && (
        <WebcamModal
          style={{ display: openCamera ? "flex" : "none" }}
          shouldVerifyUserImage={Boolean(
            user.residential?.shouldVerifyUserImage
          )}
          setUserImage={(img) => {
            setSelectedGuestData((prev) => ({
              ...prev,
              isImageVerify: false,
              image: img,
            }));
            if (user.residential.shouldVerifyUserImage)
              notify("Foto sendo validada pelo sistema!", ToastType.info, 3000);
          }}
          setOpen={setOpenCamera}
        />
      )}
      <DialogModal
        active={isGuestConfirmationModalOpen}
        onClose={() => setIsGuestConfirmationModalOpen(false)}
        ask={guestConfirmationText}
        confirmAction={async () => {
          setIsGuestConfirmationModalOpen(false);

          if (selectedGuestData.type === "condominiumService")
            setCurrentStep("release-access");
          else setCurrentStep("select-resident");
        }}
        cancelAction={() => setIsGuestConfirmationModalOpen(false)}
        confirmLabel="Sim"
        cancelLabel="Não"
      />
      <Main>
        {currentStep === "select-guest" && (
          <Container>
            <ContainerTitle>
              Cadastrar ou selecionar {guestLabel.toLocaleLowerCase()}
            </ContainerTitle>
            {selectGuestStep === "select-document" ? (
              <div>
                <RadioOptionsContainer>
                  <RadioOption
                    onClick={() => {
                      setSearchList([]);

                      setSearchingGuestType("rg");
                    }}
                    id="select-rg"
                    defaultChecked={true}
                    selected={searchingGuestType === "rg"}
                    label="RG"
                  />
                  <RadioOption
                    onClick={() => {
                      setSearchList([]);

                      setSearchingGuestType("cpf");
                    }}
                    id="select-cpf"
                    selected={searchingGuestType === "cpf"}
                    label="CPF"
                  />
                  <RadioOption
                    onClick={() => {
                      setSearchList([]);

                      setSearchingGuestType("name");
                    }}
                    id="select-name"
                    selected={searchingGuestType === "name"}
                    label="Nome"
                  />
                </RadioOptionsContainer>

                <Datalist
                  placeholder={`Digite o ${
                    searchingGuestType !== "name"
                      ? searchingGuestType.toUpperCase()
                      : "nome"
                  } do ${guestLabel.toLocaleLowerCase()}`}
                  value={
                    searchingGuestType === "cpf"
                      ? selectedGuestData.cpf
                      : searchingGuestType === "rg"
                      ? selectedGuestData.rg
                      : selectedGuestData.name
                  }
                  onChange={async (e) => {
                    const { value } = e.target;

                    if (searchingGuestType === "cpf")
                      setSelectedGuestData({
                        ...selectedGuestData,
                        cpf: value
                          .replace(/(\D)/g, "")
                          .replace(/(\d{3})(\d)/, "$1.$2")
                          .replace(/(\d{3}\.\d{3})(\d)/, "$1.$2")
                          .replace(/(\d{3}\.\d{3}\.\d{3})(\d)/, "$1-$2")
                          .replace(/(\d{3}\.\d{3}\.\d{3}-\d{2})\d/, "$1"),
                      });
                    else if (searchingGuestType === "rg")
                      setSelectedGuestData({
                        ...selectedGuestData,
                        rg: value.replace(/\W/g, "").replace(/(\w{9})\w/, "$1"),
                      });
                    else
                      setSelectedGuestData({
                        ...selectedGuestData,
                        name: value,
                      });

                    if (value.length > 0) {
                      const endpointPart =
                        searchingGuestType === "cpf"
                          ? "listCPF"
                          : searchingGuestType === "rg"
                          ? "listRg"
                          : "listName";
                      const { residentialId } = user;
                      const searched = value.replace(/[.-]/g, "");
                      const response = await api.get(
                        `/guest_register/${endpointPart}/${residentialId}/${searched}`
                      );

                      const { data } = response;

                      setSearchList(data);
                    } else setSearchList([]);
                  }}
                  list={
                    searchingGuestType !== "name"
                      ? searchList.map((item) => ({
                          key: `document-${item}`,
                          value: item,
                        }))
                      : searchList.map((item) => ({
                          key: `guest-${item}`,
                          value: item,
                        }))
                  }
                  selectValue={(value) => {
                    if (searchingGuestType === "cpf")
                      setSelectedGuestData({
                        ...selectedGuestData,
                        cpf: value
                          .replace(/(\D)/g, "")
                          .replace(/(\d{3})(\d)/, "$1.$2")
                          .replace(/(\d{3}\.\d{3})(\d)/, "$1.$2")
                          .replace(/(\d{3}\.\d{3}\.\d{3})(\d)/, "$1-$2")
                          .replace(/(\d{3}\.\d{3}\.\d{3}-\d{2})\d/, "$1"),
                      });
                    else if (searchingGuestType === "rg")
                      setSelectedGuestData({
                        ...selectedGuestData,
                        rg: value.replace(/\W/g, "").replace(/(\w{9})\w/, "$1"),
                      });
                    else
                      setSelectedGuestData({
                        ...selectedGuestData,
                        name: value,
                      });

                    setSearchList([]);
                  }}
                />
                <ButtonsContainer>
                  <Button
                    disabled={Boolean(
                      (!selectedGuestData.rg &&
                        !selectedGuestData.cpf &&
                        !selectedGuestData.name) ||
                        (selectedGuestData.rg &&
                          selectedGuestData.rg?.length <= 0) ||
                        (selectedGuestData.cpf &&
                          selectedGuestData.cpf.length <= 0) ||
                        (selectedGuestData.name &&
                          selectedGuestData.name.length <= 0)
                    )}
                    title="Confirmar"
                    onClick={async () => {
                      setIsLoadingSelectResident(true);

                      try {
                        if (
                          (!selectedGuestData.rg &&
                            !selectedGuestData.cpf &&
                            !selectedGuestData.name) ||
                          (selectedGuestData.rg &&
                            selectedGuestData.rg?.length <= 0) ||
                          (selectedGuestData.cpf &&
                            selectedGuestData.cpf.length <= 0) ||
                          (selectedGuestData.name &&
                            selectedGuestData.name.length <= 0)
                        )
                          notify(
                            `Digite um ${
                              searchingGuestType !== "name"
                                ? searchingGuestType.toUpperCase()
                                : "nome"
                            }`,
                            ToastType.error,
                            2000
                          );
                        else {
                          setSelectGuestStep("select-guest");

                          const endpointPart =
                            searchingGuestType === "cpf"
                              ? "getByCPF"
                              : searchingGuestType === "rg"
                              ? "getByRg"
                              : "getByName";

                          const { residentialId } = user;
                          const search = selectedGuestData[
                            searchingGuestType
                          ].replace(/[.-]/g, "");
                          try {
                            const response = await api.get(
                              `/guest_register/${endpointPart}/${residentialId}/${search}/${
                                user.condominiumId ?? 0
                              }`
                            );

                            const { data } = response;
                            if (data.uuid && data.uuid.length > 0) {
                              setSelectedGuestData(data);
                              setIsNewGuest(false);
                            } else setIsNewGuest(true);
                          } catch (err) {
                            setIsNewGuest(true);
                          }
                        }
                      } catch {
                        notify(
                          "Ops... Erro ao selecionar visitante!",
                          ToastType.error
                        );
                      } finally {
                        setIsLoadingSelectResident(false);
                      }
                    }}
                    loading={isLoadingSelectResident}
                  />
                </ButtonsContainer>
              </div>
            ) : (
              <FormContainer
                onSubmit={async (e) => {
                  try {
                    e.preventDefault();

                    const isValid = handleIsDataValid();
                    const guestInputCPF = selectedGuestData.cpf || "";
                    const guestInputRg = selectedGuestData.rg || "";

                    const rgData =
                      guestInputRg && guestInputRg.length > 0
                        ? (
                            await api.get(
                              `/guest_register/getByRg/${
                                user.residentialId
                              }/${guestInputRg}/${user.condominiumId ?? 0}`
                            )
                          ).data
                        : undefined;

                    const cpfData =
                      guestInputCPF && guestInputCPF.length > 0
                        ? (
                            await api.get(
                              `/guest_register/getByCPF/${
                                user.residentialId
                              }/${guestInputCPF.replace(/[.-]/g, "")}/${
                                user.condominiumId ?? 0
                              }`
                            )
                          ).data
                        : undefined;

                    if (isValid) {
                      if (
                        initialRg !== guestInputRg &&
                        rgData?.rg === guestInputRg
                      ) {
                        setGuestConfirmationText(
                          "O RG inserido já existe, porém o CPF difere do CPF do usuário correspondente a este RG. Deseja seguir mesmo assim?"
                        );
                        setIsGuestConfirmationModalOpen(true);
                      } else if (
                        initialCpf !== guestInputCPF &&
                        cpfData?.cpf === guestInputCPF?.replace(/[.-]/g, "")
                      ) {
                        setGuestConfirmationText(
                          "O CPF inserido já existe, porém o RG difere do RG do usuário correspondente a este CPF. Deseja seguir mesmo assim?"
                        );
                        setIsGuestConfirmationModalOpen(true);
                      } else if (
                        selectedGuestData.type === "condominiumService"
                      ) {
                        setRegisterGuestResidentSelected(user);
                        linkGuestToResident();
                      } else {
                        setCurrentStep("select-resident");
                      }
                    }
                  } catch {
                    notify(
                      "Ops... Erro ao cadastrar visitante!",
                      ToastType.error
                    );
                  }
                }}
              >
                <Button
                  title="Voltar"
                  onClick={() => {
                    setSearchingGuestType("rg");
                    setSelectedGuestData({} as RegisterGuestData);
                    setRegisterGuestResidentSearch({
                      ...registerGuestResidentSearch,
                      condominiumId: "",
                      towerId: "",
                    } as RegisterGuestResidentSearch);
                    setSearchList([]);
                    setSelectGuestStep("select-document");
                  }}
                  size="lg"
                />
                <FormTopContainer>
                  <PhotoContainer onClick={() => setOpenCamera(true)}>
                    {selectedGuestData.image &&
                    selectedGuestData.image.length > 0 ? (
                      <img src={selectedGuestData.image} alt="" />
                    ) : selectedGuestData.imageUUID ? (
                      <img
                        src={`${awsRoot}guests_images/${selectedGuestData.imageUUID}.jpg`}
                        alt=""
                      />
                    ) : (
                      <>
                        <MdCameraAlt size={30} />
                        <span>Adicionar foto</span>
                      </>
                    )}
                  </PhotoContainer>
                </FormTopContainer>
                <InputsContainer>
                  <ControlledInput
                    label="Nome"
                    value={selectedGuestData.name}
                    onChange={(e) => {
                      setSelectedGuestData({
                        ...selectedGuestData,
                        name: e.target.value,
                      });
                    }}
                  />
                  <ControlledInput
                    label="Telefone"
                    value={selectedGuestData.phone ?? ""}
                    onChange={(e) => {
                      setSelectedGuestData({
                        ...selectedGuestData,
                        phone: e.target.value
                          .replace(/\D/g, "")
                          .replace(/(\d{2})/, "($1)")
                          .replace(/(\(\d{2}\))(\d{4})/, "$1 $2")
                          .replace(/(\(\d{2}\) \d{4})(\d)/, "$1-$2")
                          .replace(
                            /(\(\d{2}\) \d{4})(-)(\d)(\d{4})/,
                            "$1$3$2$4"
                          )
                          .replace(/(\(\d{2}\) \d{5}-\d{4})\d/, "$1"),
                      });
                    }}
                  />
                  <ControlledInput
                    disabled={
                      !!selectedGuestData.uuid && searchingGuestType === "cpf"
                    }
                    label="CPF"
                    value={selectedGuestData.cpf}
                    onChange={(e) => {
                      const masked = e.target.value
                        .replace(/(\D)/g, "")
                        .replace(/(\d{3})(\d)/, "$1.$2")
                        .replace(/(\d{3}\.\d{3})(\d)/, "$1.$2")
                        .replace(/(\d{3}\.\d{3}\.\d{3})(\d)/, "$1-$2")
                        .replace(/(\d{3}\.\d{3}\.\d{3}-\d{2})\d/, "$1");

                      setSelectedGuestData({
                        ...selectedGuestData,
                        cpf: masked,
                      });
                    }}
                  />
                  <ControlledInput
                    disabled={
                      !!selectedGuestData.uuid && searchingGuestType === "rg"
                    }
                    label="RG"
                    value={selectedGuestData.rg}
                    onChange={(e) => {
                      setSelectedGuestData({
                        ...selectedGuestData,
                        rg: e.target.value.replace(/(\w{9})./, "$1"),
                      });
                    }}
                  />
                  <Select
                    id="guest-type"
                    label="Tipo de pessoa"
                    value={selectedGuestData.type}
                    options={personTypeList}
                    onChange={(e) => {
                      const type = e.target.value as GuestType;

                      setSelectedGuestData({
                        ...selectedGuestData,
                        type,
                      });
                    }}
                  />
                </InputsContainer>
                <CheckboxContainer>
                  <RadioOption
                    id="has-vehicle"
                    label={"Possui veículo"}
                    onClick={() =>
                      setSelectedGuestData({
                        ...selectedGuestData,
                        vehicle: !selectedGuestData.vehicle,
                      })
                    }
                    selected={selectedGuestData.vehicle}
                  />
                </CheckboxContainer>
                {selectedGuestData.vehicle && (
                  <InputsContainer>
                    <ControlledInput
                      label="Modelo"
                      value={selectedGuestData.carModel}
                      onChange={(e) => {
                        setSelectedGuestData({
                          ...selectedGuestData,
                          carModel: e.target.value,
                        });
                      }}
                    />
                    <ControlledInput
                      label="Placa"
                      value={selectedGuestData.carPlate}
                      onChange={(e) => {
                        setSelectedGuestData({
                          ...selectedGuestData,
                          carPlate: e.target.value.toUpperCase(),
                        });
                      }}
                    />
                    <ControlledInput
                      label="Cor"
                      value={selectedGuestData.carColor}
                      onChange={(e) => {
                        setSelectedGuestData({
                          ...selectedGuestData,
                          carColor: e.target.value,
                        });
                      }}
                    />
                  </InputsContainer>
                )}
                <ButtonsContainer>
                  <Button
                    type="submit"
                    title={
                      isNewGuest
                        ? "Cadastrar"
                        : `Salvar e selecionar ${residentLabel.toLocaleLowerCase()}`
                    }
                    size={isNewGuest ? "sm" : "xl"}
                  />
                </ButtonsContainer>
              </FormContainer>
            )}
          </Container>
        )}

        {currentStep === "select-resident" && (
          <>
            {selectResidentStep === "type" ? (
              <Container>
                <Button
                  title="Voltar"
                  onClick={() => {
                    setRegisterGuestResidentSelected(null);
                    setSelectResidentStep("type");
                    setRegisterGuestResidentSearch(null);
                    setSelectableResidentsList([]);
                    setRegisterGuestResidents([]);

                    setSearchList([]);
                    setCurrentStep("select-guest");
                    setSelectGuestStep("select-guest");
                  }}
                />

                <ContainerTitle>
                  Selecione o tipo de {residentLabel.toLocaleLowerCase()}
                </ContainerTitle>

                <ButtonsContainer>
                  {selectedGuestData.users &&
                    selectedGuestData.users.filter(
                      (item) => !item.permission.includes("Porter")
                    ).length > 0 && (
                      <Button
                        onClick={() => {
                          setSelectedResidentType("linked");
                          setSelectResidentStep("selection");
                        }}
                        title={
                          user.residential?.labelType === "commercial"
                            ? "Condômino já vinculado"
                            : "Morador já vinculado"
                        }
                        size="lg"
                      />
                    )}
                  <Button
                    onClick={() => {
                      setSelectedResidentType("not-linked");
                      setSelectResidentStep("selection");
                    }}
                    title={
                      user.residential?.labelType === "commercial"
                        ? "Vincular ao condômino"
                        : "Morador não vinculado"
                    }
                    size="lg"
                  />
                  <Button
                    onClick={linkGuestToResident}
                    title={
                      user.residential?.labelType === "commercial"
                        ? "Condômino inexistente"
                        : "Morador não cadastrado"
                    }
                    size="lg"
                  />
                </ButtonsContainer>
              </Container>
            ) : (
              <>
                {selectedResidentType === "not-linked" && (
                  <Container>
                    <Button
                      title="Voltar"
                      onClick={() => {
                        setSelectResidentStep("type");
                        setRegisterGuestResidentSelected(null);
                        setSelectableResidentsList([]);
                        setRegisterGuestResidentSearch(null);
                        setRegisterGuestResidents([]);
                      }}
                    />
                    <ContainerTitle>
                      Buscar por {residentLabel.toLocaleLowerCase()}
                    </ContainerTitle>

                    <FormContainer
                      onSubmit={(e) => {
                        try {
                          e.preventDefault();

                          setIsLoadingSearch(true);

                          if (registerGuestResidentSearch?.apartmentId)
                            searchResidentsByApartment(
                              registerGuestResidentSearch?.apartmentId
                            );
                          else if (companyApartmentId)
                            searchResidentsByApartment(companyApartmentId);
                          else if (registerGuestResidentSearch?.resident)
                            searchResidentsByName(
                              registerGuestResidentSearch?.resident
                            );
                          setRegisterGuestResidentSelected(null);
                        } catch {
                          notify(
                            "Ops... Error ao selecionar morador!",
                            ToastType.error
                          );
                        } finally {
                          setIsLoadingSearch(false);
                        }
                      }}
                    >
                      <SelectContainer>
                        {user.permission === Permission.GeneralPorter && (
                          <Select
                            id="condominium-list"
                            label="Condomínio"
                            options={condominiaList}
                            value={
                              registerGuestResidentSearch?.condominiumId ?? ""
                            }
                            onChange={(e) => {
                              if (registerGuestResidentSearch)
                                setRegisterGuestResidentSearch({
                                  ...registerGuestResidentSearch,
                                  condominiumId: e.target.value,
                                  resident: "",
                                  company: "",
                                });
                              else
                                setRegisterGuestResidentSearch({
                                  condominiumId: e.target.value,
                                  towerId: "",
                                  apartmentId: "",
                                  resident: "",
                                  company: "",
                                });
                            }}
                          />
                        )}
                        <Select
                          id="tower-list"
                          label={towerLabel}
                          options={towersList}
                          value={registerGuestResidentSearch?.towerId ?? ""}
                          onChange={(e) => {
                            if (registerGuestResidentSearch)
                              setRegisterGuestResidentSearch({
                                ...registerGuestResidentSearch,
                                towerId: e.target.value,
                                resident: "",
                                company: "",
                              });
                            else
                              setRegisterGuestResidentSearch({
                                towerId: e.target.value,
                                apartmentId: "",
                                resident: "",
                                company: "",
                              } as RegisterGuestResidentSearch);
                          }}
                        />
                        <Select
                          id="apartment-list"
                          label={apartmentLabel}
                          options={occupiedApartmentsList}
                          value={registerGuestResidentSearch?.apartmentId ?? ""}
                          onChange={(e) => {
                            if (!registerGuestResidentSearch)
                              setRegisterGuestResidentSearch({
                                towerId: "",
                                condominiumId: "",
                                resident: "",
                                company: "",
                                apartmentId: e.target.value,
                              });
                            else
                              setRegisterGuestResidentSearch({
                                ...registerGuestResidentSearch,
                                resident: "",
                                company: "",
                                apartmentId: e.target.value,
                              });

                            setCompanyApartmentId(0);
                          }}
                        />
                      </SelectContainer>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns:
                            user?.residential?.labelType === "commercial"
                              ? "repeat(2, 1fr)"
                              : "1fr",
                          gap: "1rem",
                        }}
                      >
                        <div>
                          <h3 style={{ margin: ".5rem 0 .25rem" }}>
                            Buscar por nome
                          </h3>
                          <Datalist
                            value={registerGuestResidentSearch?.resident || ""}
                            onChange={(e) => {
                              if (user.permission === "generalPorter")
                                setTowersList([]);
                              setOccupiedApartmentsList([]);

                              if (!registerGuestResidentSearch)
                                setRegisterGuestResidentSearch({
                                  company: "",
                                  apartmentId: "",
                                  towerId: "",
                                  condominiumId: "",
                                  resident: e.target.value,
                                });
                              else
                                setRegisterGuestResidentSearch({
                                  ...registerGuestResidentSearch,
                                  company: "",
                                  apartmentId: "",
                                  towerId: "",
                                  condominiumId: "",
                                  resident: e.target.value,
                                });
                            }}
                            list={residentList.map((resident) => {
                              const value = `${resident.name}${
                                resident.surname ? " " + resident.surname : ""
                              }`;

                              return {
                                key: `residents-datalist-${resident.id}`,
                                value,
                              };
                            })}
                            selectValue={(value) => {
                              if (!registerGuestResidentSearch)
                                setRegisterGuestResidentSearch({
                                  company: "",
                                  apartmentId: "",
                                  towerId: "",
                                  condominiumId: "",
                                  resident: value,
                                });
                              else
                                setRegisterGuestResidentSearch({
                                  ...registerGuestResidentSearch,
                                  company: "",
                                  apartmentId: "",
                                  towerId: "",
                                  condominiumId: "",
                                  resident: value,
                                });

                              setCompanyApartmentId(0);
                              setResidentList([]);
                            }}
                          />
                        </div>
                        {user?.residential?.labelType === "commercial" && (
                          <div>
                            <h3 style={{ margin: ".5rem 0 .25rem" }}>
                              Buscar por empresa
                            </h3>
                            <Datalist
                              value={registerGuestResidentSearch?.company || ""}
                              onChange={(e) => {
                                if (user.permission === "generalPorter")
                                  setTowersList([]);
                                setOccupiedApartmentsList([]);

                                if (!registerGuestResidentSearch)
                                  setRegisterGuestResidentSearch({
                                    apartmentId: "",
                                    towerId: "",
                                    condominiumId: "",
                                    resident: "",
                                    company: e.target.value,
                                  });
                                else
                                  setRegisterGuestResidentSearch({
                                    ...registerGuestResidentSearch,
                                    company: e.target.value,
                                  });
                              }}
                              list={companiesList.map((company) => {
                                const value = `${company.name} - CNPJ: ${company.cnpj} - Sala: ${company.apartment.name}`;

                                return {
                                  key: String(company.apartmentId),
                                  value,
                                };
                              })}
                              selectValue={(value) => {
                                setRegisterGuestResidentSearch({
                                  apartmentId: "",
                                  towerId: "",
                                  condominiumId: "",
                                  resident: "",
                                  company: value,
                                });

                                setCompaniesList([]);
                              }}
                              selectKey={(key) => setCompanyApartmentId(+key)}
                            />
                          </div>
                        )}
                      </div>
                      <ButtonsContainer style={{ marginTop: "1rem" }}>
                        <Button
                          type="submit"
                          size="xl"
                          loading={isLoadingSearch}
                          disabled={
                            !registerGuestResidentSearch?.apartmentId &&
                            !registerGuestResidentSearch?.resident &&
                            !registerGuestResidentSearch?.company
                          }
                          title="Buscar"
                        />
                      </ButtonsContainer>
                    </FormContainer>
                  </Container>
                )}

                {selectableResidentsList.length > 0 ? (
                  <Container>
                    <Button
                      title="Voltar"
                      onClick={() => {
                        setSelectResidentStep("type");
                        setRegisterGuestResidentSelected(null);
                        setSelectableResidentsList([]);
                        setRegisterGuestResidentSearch(null);
                        setRegisterGuestResidents([]);
                      }}
                    />
                    <ContainerTitle>
                      Selecionar {residentLabel.toLocaleLowerCase()}
                    </ContainerTitle>
                    <UserCardsContainer>
                      {_.uniqBy(
                        selectableResidentsList.filter(
                          (resident) => resident.id !== user.id
                        ),
                        (item) => item.id
                      ).map((resident) => (
                        <RegisterPeopleUserCard
                          key={`residents-list-${resident.id}`}
                          selected={
                            typeof registerGuestResidentSelected !== "string" &&
                            resident.id === registerGuestResidentSelected?.id
                          }
                          onClick={() => {
                            if (
                              registerGuestResidentSelected?.id !== resident.id
                            )
                              setRegisterGuestResidentSelected(resident);
                            else setRegisterGuestResidentSelected(null);
                          }}
                          user={resident}
                        />
                      ))}
                    </UserCardsContainer>
                    <ButtonsContainer>
                      <Button
                        onClick={async () => {
                          setIsLoadingCall(true);

                          try {
                            if (registerGuestResidentSelected)
                              await startCall(registerGuestResidentSelected);
                          } catch {
                            notify(
                              "Ops... Erro ao iniciar chamada!",
                              ToastType.error
                            );
                          } finally {
                            setIsLoadingCall(false);
                          }
                        }}
                        disabled={!registerGuestResidentSelected}
                        title="Ligar"
                        size="xl"
                        loading={isLoadingCall}
                      />
                      <Button
                        disabled={!registerGuestResidentSelected}
                        onClick={async () => await linkGuestToResident()}
                        title="Selecionar"
                        size="xl"
                      />
                    </ButtonsContainer>
                  </Container>
                ) : selectedResidentType === "linked" ? (
                  <Container>
                    <Button
                      title="Voltar"
                      onClick={() => {
                        setSelectResidentStep("type");
                        setRegisterGuestResidentSelected(null);
                        setSelectableResidentsList([]);
                        setRegisterGuestResidentSearch(null);
                        setRegisterGuestResidents([]);
                      }}
                    />
                    <p
                      style={{
                        fontWeight: "bold",
                        marginTop: 10,
                        textAlign: "center",
                      }}
                    >
                      Nenhum {residentLabel.toLocaleLowerCase()} já vinculado a
                      este {guestLabel.toLocaleLowerCase()}.
                    </p>
                  </Container>
                ) : undefined}
              </>
            )}
          </>
        )}

        {currentStep === "release-access" && (
          <Container>
            <Button
              title="Voltar"
              onClick={async () => {
                setReleaseGuestForm({
                  ...initialReleaseGuestForm,
                  doorsId: [],
                });

                if (selectedGuestData.type === "condominiumService")
                  setCurrentStep("select-guest");
                else setCurrentStep("select-resident");
              }}
            />
            <ContainerTitle>Liberar acesso</ContainerTitle>
            <FormContainer onSubmit={releaseAccess}>
              <InputsContainer style={{ gridTemplateColumns: "1fr 1fr" }}>
                <ControlledInput
                  label="Data inicial da entrada"
                  value={releaseGuestForm.initialDate}
                  type="date"
                  min={moment().format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setReleaseGuestForm({
                      ...releaseGuestForm,
                      initialDate: e.target.value,
                    })
                  }
                />
                <ControlledInput
                  label="Hora inicial da entrada"
                  value={releaseGuestForm.initialTime}
                  type="time"
                  onChange={(e) =>
                    setReleaseGuestForm({
                      ...releaseGuestForm,
                      initialTime: e.target.value,
                    })
                  }
                />
                <ControlledInput
                  label="Data de expiração da entrada"
                  value={releaseGuestForm.expirationDate}
                  min={moment().format("YYYY-MM-DD")}
                  type="date"
                  onChange={(e) =>
                    setReleaseGuestForm({
                      ...releaseGuestForm,
                      expirationDate: e.target.value,
                    })
                  }
                />

                <ControlledInput
                  label="Hora de expiração da entrada"
                  value={releaseGuestForm.expirationTime}
                  type="time"
                  onChange={(e) =>
                    setReleaseGuestForm({
                      ...releaseGuestForm,
                      expirationTime: e.target.value,
                    })
                  }
                />
              </InputsContainer>

              <CheckboxContainer>
                <ReleaseAccessFormSubtitle>
                  Portas onde será permitido o acesso:
                </ReleaseAccessFormSubtitle>
                {doorList.length > 0 &&
                  doorList.map((door) => (
                    <Checkbox
                      key={`door-${door.id}`}
                      id={`door-${door.id}`}
                      checked={
                        releaseGuestForm
                          ? releaseGuestForm.doorsId.some(
                              (id) => door.id === id
                            )
                          : false
                      }
                      setChecked={() => {
                        const { doorsId } = releaseGuestForm;

                        const checkedId = doorsId.findIndex(
                          (id) => door.id === id
                        );
                        if (checkedId !== -1) doorsId.splice(checkedId);
                        else doorsId.push(door.id);

                        setReleaseGuestForm({
                          ...releaseGuestForm,
                          doorsId: doorsId,
                        });
                      }}
                      label={door.name}
                    />
                  ))}
              </CheckboxContainer>
              <EntriesQuantityContainer>
                <ReleaseAccessFormSubtitle style={{ marginRight: ".5rem" }}>
                  Número de entrada(s) permitida(s) por porta dentro do período
                  de tempo:
                </ReleaseAccessFormSubtitle>
                <input
                  style={{
                    width: "3rem",
                    height: "1.5rem",
                    paddingLeft: ".25rem",
                  }}
                  type="number"
                  defaultValue={1}
                  value={releaseGuestForm.usesLeft}
                  onChange={(e) =>
                    setReleaseGuestForm({
                      ...releaseGuestForm,
                      usesLeft: +e.target.value,
                    })
                  }
                  step={1}
                  min={1}
                />
              </EntriesQuantityContainer>
              <ButtonsContainer>
                <Button
                  loading={loadingReleaseAccess}
                  title="Confirmar"
                  type="submit"
                />
              </ButtonsContainer>
            </FormContainer>
          </Container>
        )}
      </Main>
    </Layout>
  );
}
