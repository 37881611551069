import styled from "styled-components";

export const Container = styled.div`
  /* overflow: hidden; */
`;

export const GuestsSections = styled.div`
  display: flex;
  height: calc(100% - (67.2px));
`;

export const GuestsSection = styled.section`
  padding: 0.5rem;
  margin: 1rem 0;
  height: 100%;
  width: 50%;
`;

export const SectionTitle = styled.h1`
  font-size: 1.5rem;
  text-align: center;
`;

export const GuestCards = styled.div`
  overflow: auto;
  height: calc(100% - (1rem + 1.75rem));
  position: relative;
  overflow: auto;
  margin-top: 1.5rem;

  & > div {
    width: 100%;
  }

  & > div:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
`;
