import { Text } from "./styles";

interface NotFoundMessageProps {
  message?: string;
  marginTop?: boolean;
}

export default function NotFoundMessage({
  message = "Nenhuma informação encontrada.",
  marginTop = false,
}: NotFoundMessageProps) {
  const classes = [marginTop ? "margin-top" : ""];

  return <Text className={classes.join(" ")}>{message}</Text>;
}
