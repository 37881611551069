import styled from "styled-components";

export const Avatar = styled.figure`
  background-color: var(--gray-300);
  display: flex;
  overflow: hidden;
  width: 6.5rem;
  height: 6.5rem;
  border: 0.125rem var(--gray-900) solid;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
  }

  &.sm {
    width: 5rem;
    height: 5rem;
  }

  &.xs {
    width: 3.5rem;
    height: 3.5rem;
  }
`;
