import Notification from "./Notification";
import DateBox from "../DateBox";
import { Container, NotificationsContainer } from "./styles";
import { useRecoilState, useSetRecoilState } from "recoil";
import notificationsState from "../../states/notificationsState";
import { useCallback, useEffect } from "react";
import selectedUserToChatState, {
  SelectedUserToChat,
} from "../../states/selectedUserToChatState";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AccessNotificationData,
  AppNotification,
  CallNotificationData,
  EmergencyNotificationData,
  MessageNotificationData,
  OpenDoorNotificationData,
  PackageNotificationData,
} from "../../interfaces/AppNotification";
import NotFoundMessage from "../NotFoundMessage";
import userState from "../../states/userState";
import { api } from "../../services/api";
import AccessLog from "../../interfaces/AccessLog";
import moment from "moment";

type ShowingNotification =
  | MessageNotificationData
  | OpenDoorNotificationData
  | CallNotificationData
  | EmergencyNotificationData
  | AccessNotificationData;

export default function AsideNotifications() {
  const navigate = useNavigate();
  const location = useLocation();

  const [user] = useRecoilState(userState);

  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const setSelectedUserToChat = useSetRecoilState(selectedUserToChatState);

  const navToMessagesPage = useCallback(
    (notification: AppNotification) => {
      const newNotifications = [...notifications].filter((item) => {
        return (
          item.type !== "PhotoValidationNotification" &&
          (item.type === "OpenDoorNotification" ||
            item.type === "CallNotification" ||
            item.type === "Default" ||
            item.type === "AccessNotification" ||
            item.type === "EmergencyNotification" ||
            item.type === "VehicleNotification" ||
            item.type === "PackageNotification" ||
            (notification.type === "MessageNotification" &&
              item.data.user_id !== notification.data.user_id))
        );
      });

      setNotifications(newNotifications);
      localStorage.setItem(
        `guugWebPorter.notifications.user${user.id}`,
        JSON.stringify(newNotifications)
      );

      const data = notification.data as MessageNotificationData;

      const selectedUser: SelectedUserToChat = {
        id: parseInt(data.user_id),
        name: data.user_name,
        phone: data.user_phone ?? "",
        shouldCallVoip: Boolean(data.shouldCallVoip),
      };
      setSelectedUserToChat(selectedUser);

      if (!location.pathname.includes("/mensagens")) navigate("/mensagens");
    },
    [
      location.pathname,
      navigate,
      notifications,
      setNotifications,
      setSelectedUserToChat,
      user.id,
    ]
  );

  useEffect(() => {
    const element = document.getElementById(
      "aside-notifications"
    ) as HTMLDivElement;
    const scrollHeight = element.scrollHeight;

    element.scrollTo(0, scrollHeight);
  }, [notifications]);

  return (
    <Container id="aside-notifications">
      <NotificationsContainer>
        {notifications.length > 0 ? (
          notifications.map((notification, i) => {
            const properties = {
              clickable: false,
              onClick: () => {},
            };

            if (notification.type === "MessageNotification") {
              properties.clickable = true;
              properties.onClick = () => navToMessagesPage(notification);
            }

            return (
              <Notification
                notificationContent={notification.data as ShowingNotification}
                key={i}
                notificationData={notification}
                clickable={properties.clickable}
                onClick={properties.onClick}
              />
            );
          })
        ) : (
          <NotFoundMessage message="Nenhuma notificação." />
        )}
      </NotificationsContainer>
    </Container>
  );
}
