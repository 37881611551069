import { MouseEventHandler, useState } from "react";
import { MdClose } from "react-icons/md";
import CancelButton from "../CancelButton";
import ConfirmButton from "../ConfirmButton";
import { Body, Foot, Head, Modal, Overlay } from "./styles";

interface DialogModalProps {
  active: boolean;
  title?: string;
  ask: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onClose: () => void;
  confirmAction: MouseEventHandler<HTMLButtonElement>;
  cancelAction: MouseEventHandler<HTMLButtonElement>;
}

export default function DialogModal({
  active,
  title = "",
  ask,
  confirmLabel = "Confirmar",
  cancelLabel = "Cancelar",
  onClose,
  confirmAction,
  cancelAction,
}: DialogModalProps) {
  const headClasses = [title === "" ? "no-title" : ""];

  const [isLoadingConfirmAction, setIsLoadingConfirmAction] = useState(false);

  return (
    (active && (
      <Overlay onClick={() => onClose()}>
        <Modal onClick={(event) => event.stopPropagation()}>
          <Head className={headClasses.join(" ")}>
            {title && <h1>{title}</h1>}
            <button onClick={() => onClose()}>
              <MdClose />
            </button>
          </Head>
          <Body>
            <h3>{ask}</h3>
          </Body>
          <Foot>
            <ConfirmButton
              title={confirmLabel}
              loading={isLoadingConfirmAction}
              onClick={(e) => {
                setIsLoadingConfirmAction(true);

                try {
                  confirmAction(e);
                } catch {
                } finally {
                  setIsLoadingConfirmAction(false);
                }
              }}
            />
            <CancelButton title={cancelLabel} onClick={cancelAction} />
          </Foot>
        </Modal>
      </Overlay>
    )) || <></>
  );
}
