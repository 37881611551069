import * as VoxImplant from "voximplant-websdk";
import { api } from "../services/api";
import { getFirebaseToken } from "../firebase";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import userState from "../states/userState";
import { User } from "../interfaces/User";
import { useNavigate } from "react-router-dom";
import notificationsState from "../states/notificationsState";

const unsubscribeToFirebaseTopics = async (user: User) => {
  const token = await getFirebaseToken();

  await api.post("wp_notification_manager/unsubscribe", {
    fcm: token,
    topic: String(user.id),
  });

  if (user.condominiumId)
    await api.post("wp_notification_manager/unsubscribe", {
      fcm: token,
      topic: `condominium_${user.condominiumId}`,
    });

  if (user.residentialId)
    await api.post("wp_notification_manager/unsubscribe", {
      fcm: token,
      topic: `residential_${user.residentialId}`,
    });

  if (user.permission === "generalPorter")
    await api.post("wp_notification_manager/unsubscribe", {
      fcm: token,
      topic: `generalPorter_${user.residentialId}`,
    });
  else if (user.permission === "localPorter")
    await api.post("wp_notification_manager/unsubscribe", {
      fcm: token,
      topic: `localPorter_${user.condominiumId}`,
    });
};

const useCleanStorage = () => {
  const [user, setUser] = useRecoilState(userState);
  const setNotifications = useSetRecoilState(notificationsState);

  return () => {
    localStorage.setItem("guugWebPorter.user", "");
    localStorage.setItem(
      `guugWebPorter.notifications.user${user.id}`,
      JSON.stringify([])
    );
    setUser({} as User);
    setNotifications([]);
  };
};

const useLogout = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);

  const cleanStorage = useCleanStorage();

  return async () => {
    try {
      const clientState = VoxImplant.getInstance().getClientState();

      if (
        clientState !== VoxImplant.ClientState.DISCONNECTED &&
        clientState !== VoxImplant.ClientState.CONNECTING
      )
        await VoxImplant.getInstance().disconnect();

      await unsubscribeToFirebaseTopics(user);

      cleanStorage();

      navigate("/");
    } catch (e) {
      cleanStorage();

      navigate("/");
    }
  };
};

export default useLogout;
