import { MouseEventHandler } from "react";
import { Button } from "./styles";
import { BeatLoader } from "react-spinners";

interface CancelButtonProps {
  title: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
}

export default function index({ title, onClick, loading }: CancelButtonProps) {
  return (
    <Button onClick={onClick} disabled={loading}>
      {loading ? <BeatLoader color="#fff" /> : title}
    </Button>
  );
}
