import { Text } from "./styles";

interface LoadingMessageProps {
  text?: string;
  parentType?: "flex-parent" | "not-flex-parent";
}

export default function LoadingMessage({
  text = "Carregando...",
  parentType = "flex-parent",
}: LoadingMessageProps) {
  const classes = [parentType];

  return <Text className={classes.join(" ")}>{text}</Text>;
}
