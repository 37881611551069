import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  height: 500px;
  width: 500px;
`;

export const TakePictureButton = styled.button`
  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
  border: 0.25rem solid var(--orange-500);
  padding: 0.25rem;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }

  & > div {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: var(--orange-500);
  }
`;
