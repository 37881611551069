import { atom } from "recoil";
import { Apartment } from "../interfaces/Apartment";
import { Condominium } from "../interfaces/Condominium";
import { Tower } from "../interfaces/Tower";
import { Permission, User } from "../interfaces/User";

export interface SelectedUserToChat {
  id: number;
  name: string;
  surname?: string;
  phone: string;
  content?: string;
  lastMessageUnseen?: boolean;
  permission?: Permission;
  apartment?: Apartment;
  tower?: Tower;
  condominium?: Condominium;
  imageUUID?: string;
  shouldCallVoip: boolean;
  company?: string;
  residentType?: "owner" | "tenant" | "employee";
}

const selectedUserToChat = atom({
  key: "selectedUserToChat",
  default: {} as SelectedUserToChat | User,
});

export default selectedUserToChat;
