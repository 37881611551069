import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    /* grid-template-columns: repeat(2, 1fr);
    gap: 2rem; */
`;

export const SelectsContainer = styled.section`
    & > div:not(:last-of-type) {
        margin-bottom: 0.5rem;
    }

    margin-bottom: 1rem;
`;

export const SelectContainer = styled.div``;

export const AutomationCardsContainer = styled.section`
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
`;
