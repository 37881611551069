import { Container } from "./styles";
import Tab from "./Tab";

interface TabItem {
  title: string;
  path: string;
}

interface TabsProps {
  items: TabItem[];
}

export default function Tabs({ items }: TabsProps) {
  return (
    <Container>
      {items.length > 0 &&
        items.map((item, i) => (
          <Tab key={i} title={item.title} path={item.path} />
        ))}
    </Container>
  );
}
