import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ContentType } from ".";
import Button from "../../components/Button";
import ControlledInput from "../../components/ControlledInput";
import Package from "../../interfaces/Package";
import { api } from "../../services/api";
import userState from "../../states/userState";
import PackageCard from "./components/PackageCard";

const itemsPerPage = 50;

type Props = {
  setPaths: React.Dispatch<React.SetStateAction<ContentType[]>>;
  setSelectedPack: (value: any) => void;
};

export default function PackagesList({ setPaths, setSelectedPack }: Props) {
  const user = useRecoilValue(userState);

  const [packages, setPackages] = useState<Package[]>([]);
  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState("");

  const getPackages = useCallback(
    async (isSearching?: boolean, clean?: boolean) => {
      if (total === 0 || packages.length !== total || isSearching) {
        const endpoint = `resident-package/all/${itemsPerPage}/${
          isSearching || clean ? 0 : packages.length
        }/${user.residentialId}/${user.condominiumId ?? 0}/0/0/${search}`;

        const res = await api.get(endpoint);

        const {
          data: { rows, count },
        } = res;

        if (total === 0) setTotal(count);

        if (packages.length !== count)
          if (packages.length < count) {
            if (clean) setPackages(rows);
            else setPackages([...packages, ...rows]);
          } else {
            setPackages(rows);
          }
      }
    },
    [packages, search, total, user.condominiumId, user.residentialId]
  );

  useEffect(() => {
    getPackages();
  }, []);

  useEffect(() => {
    if (search.length) getPackages(true);
    else getPackages(false, true);
  }, [search]);

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <h1
        style={{
          textAlign: "center",
          marginBottom: "0.5rem",
        }}
      >
        Lista de encomendas
      </h1>
      <ControlledInput
        placeholder="Buscar por nome do usuário..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div
        style={{
          margin: "10px 0",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          size="lg"
          title="Adicionar nova encomenda"
          onClick={() => {
            setPaths((paths) => [...paths, "select"]);

            setSelectedPack(null);
          }}
        />
      </div>
      <div
        onScroll={async (e) => {
          const elem = e.target as HTMLDivElement;

          if (elem.scrollTop + elem.clientHeight === elem.scrollHeight)
            await getPackages();
        }}
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          gap: 15,
          padding: "15px 5px",
          overflow: "auto",
        }}
      >
        {packages.length > 0 &&
          packages.map((item, i) => (
            <PackageCard
              key={item.id + "-" + i}
              imageUUID={item.resident?.imageUUID}
              name={item.resident?.name ?? "Usuário não cadastrado"}
              status={item.status}
              condominium={item.apartment?.tower.condominium.name}
              tower={item.apartment?.tower.name}
              apartment={item.apartment?.name}
              onClick={() => {
                setPaths((paths) => [...paths, "details"]);

                setSelectedPack(item);
              }}
            />
          ))}
      </div>
    </div>
  );
}
