import styled from "styled-components";

export const Overlay = styled.div`
  z-index: 999;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000a;
`;

export const Modal = styled.div`
  z-index: 1000;
  background-color: var(--gray-50);
  border-radius: 0.25rem;
  margin: 0 1rem;
  padding: 1rem;
  max-width: 400px;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  width: 100%;

  &.no-title {
    justify-content: flex-end;
  }

  h1 {
    font-size: 1.25rem;
    margin-right: 0.5rem;
    width: 70%;
  }

  button {
    margin-left: auto;
    align-self: flex-start;
    background-color: #0000;
    border: none;
    color: var(--red-500);
    font-size: 1.5rem;
  }
`;

export const Body = styled.div`
  padding: 1rem 0;

  h3 {
    font-size: 1rem;
  }
`;

export const Foot = styled.div`
  display: flex;
  justify-content: space-evenly;

  & > button:not(:last-of-type) {
    margin-right: 0.25rem;
  }
`;
