import { ChangeEventHandler } from "react";
import { Container } from "./styles";

export interface Option {
  id: number | string;
  name: string;
}

interface SelectProps {
  id: string;
  label: string;
  options: Option[];
  placeholder?: string;
  size?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  disabled?: boolean;
}

export default function Select({
  id,
  label,
  options,
  placeholder = "Selecione...",
  value = "",
  onChange = () => {},
  size = "md",
  disabled = false,
}: SelectProps) {
  const sorted = options.sort((a, b) =>
    a.name.localeCompare(b.name, "pt-BR", { numeric: true })
  );

  return (
    <Container>
      <label htmlFor={id}>{label}</label>
      <select
        value={value}
        onChange={onChange}
        name=""
        id={id}
        className={size}
        disabled={disabled}
      >
        <option value="">{placeholder}</option>
        {sorted.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </Container>
  );
}
