import React from "react";
import { Container } from "./styles";

interface PngIconProps {
    icon: string;
}

export default function PngIcon({ icon }: PngIconProps) {
    return (
        <Container>
            <img src={icon} alt="" />
        </Container>
    );
}
