import { useState } from "react";
import Layout from "../../components/Layout";
import { Container } from "../../components/Layout/styles";
import PackagesList from "./PackagesList";
import PackageDetails from "./PackageDetails";
import PackageForm from "./PackageForm";
import SelectResident from "./SelectResident";
import Button from "../../components/Button";
import Package from "../../interfaces/Package";

export type ContentType = "list" | "details" | "form" | "select";

interface Props {}

export default function Packages() {
  const [paths, setPaths] = useState<ContentType[]>(["list"]);

  const [selectedPack, setSelectedPack] = useState<Package>({} as Package);

  const [selectedApartmentId, setSelectedApartmentId] = useState(0);
  const [selectedResidentId, setSelectedResidentId] = useState(0);

  const Content = ({ content }: { content: ContentType }) => {
    switch (content) {
      case "list":
        return (
          <PackagesList setPaths={setPaths} setSelectedPack={setSelectedPack} />
        );
      case "details":
        return (
          <PackageDetails
            setPaths={setPaths}
            pack={selectedPack}
            setSelectedPack={setSelectedPack}
            setSelectedApartmentId={setSelectedApartmentId}
            setSelectedResidentId={setSelectedResidentId}
          />
        );
      case "form":
        return (
          <PackageForm
            setPaths={setPaths}
            setSelectedPack={setSelectedPack}
            selectedPack={selectedPack}
            apartmentId={selectedApartmentId}
            residentId={selectedResidentId}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <Layout>
      {paths[paths.length - 1] === "select" ? (
        <SelectResident
          setPaths={setPaths}
          setSelectedApartmentId={setSelectedApartmentId}
          setSelectedResidentId={setSelectedResidentId}
        />
      ) : (
        <Content content={paths[paths.length - 1]} />
      )}
    </Layout>
  );
}
