import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white);
  padding: 0.75rem;
  border: 0.125rem solid var(--gray-900);
  border-radius: 0.25rem;
  transition: background-color 0.2s;

  &.md {
    width: 18rem;
  }

  &.lg {
    width: 24rem;
  }
`;

export const AutomationInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  figure {
    margin-right: 1rem;
  }
`;

export const AutomationTextData = styled.div`
  flex: 1;

  h4 {
    font-size: 1.125rem;
    color: var(--orange-500);
  }

  p {
    font-size: 0.9rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;

  & > button:not(:last-of-type) {
    margin-right: 0.25rem;
  }
`;
