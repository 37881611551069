import styled from "styled-components";

export const DetailsContainer = styled.div`
  background-color: #fff;
  padding: 15px;
  max-width: 600px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px auto;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GapRow = styled.div`
  display: flex;
  gap: 20px;
`;

export const DetailCard = styled.div`
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px;
`;

export const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  height: 100px;
  outline: none;
  border: none;
  font-family: "Inter";
  font-size: 18px;
`;

export const PhotoInput = styled.div`
  border: 2px solid #000;
  background-color: #fff;
  width: 200px;
  height: 200px;
  margin: 0 auto 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;
