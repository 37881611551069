import styled from "styled-components";

export const Container = styled.div``;

export const GuestSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  figure {
    margin-right: 1rem;
  }
`;

export const ResidentHr = styled.div`
  border-bottom: 0.125rem solid var(--orange-200);
  position: relative;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  h6 {
    background-color: var(--gray-50);
    padding: 0 0.25rem;
    color: var(--orange-500);
    font-size: 0.75rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const GuestInfo = styled.div`
  h4 {
    font-size: 1.25rem;
    color: var(--orange-500);
  }
`;

export const VehicleSubSection = styled.div`
  margin: 0.5rem 0;

  h5 {
    font-size: 1rem;
  }
`;

export const ResidentSection = styled.div`
  flex: 1;
  transition: height 0.5s;

  figure {
    margin-left: 1rem;
  }
`;

export const ResidentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ResidentTextData = styled.div`
  h4 {
    font-size: 1.25rem;
    color: var(--orange-500);
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
`;
