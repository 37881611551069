import { useCallback, useEffect, useState } from "react";
import { MdDelete, MdInfoOutline } from "react-icons/md";
import { useRecoilState } from "recoil";
import GuestDetails from "../../../components/GuestDetails";
import Layout from "../../../components/Layout";
import Modal from "../../../components/Modal";
import Table from "../../../components/Table";
import { Door } from "../../../interfaces/Door";
import { Guest } from "../../../interfaces/Guest";
import { api } from "../../../services/api";
import userState from "../../../states/userState";
import TopMenu from "../components/TopMenu";
import { Container } from "../styles";
import Access from "../../../interfaces/Access";
import moment from "moment";
import { ActionTd } from "../../../components/Table/styles";

interface PassByDoor {
  Door: Door;
  id: number;
  doorId: number;
  passwordId: number;
  createdAt: string;
  updatedAt: string;
  usesLeft: number;
}

interface PassPending {
  GuestRegister: Guest;
  id: number;
  expirationTime: string;
  guestRegisterId: number;
  initialTime: string;
  passCode: string;
  createdAt: string;
  updatedAt: string;
}

interface Pending {
  passByDoor: PassByDoor[];
  passPending: PassPending;
}

export default function ScheduledGuests() {
  const [user] = useRecoilState(userState);

  const residentLabel =
    user?.residential?.labelType !== "commercial"
      ? "Morador"
      : "Pessoa interna";
  const guestLabel =
    user?.residential?.labelType !== "commercial"
      ? "Visitante"
      : "Pessoa externa";

  const [guests, setGuests] = useState<any[][]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isGuestsFetching, setIsGuestsFetching] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");

  const showDetails = useCallback((guest: any) => {
    setCurrentDetailedGuest(guest);

    setIsDetailsModalOpen(true);
  }, []);

  const fetchGuests = useCallback(
    async (currentPage: number, search?: string) => {
      setIsGuestsFetching(true);

      const limit = 10;
      const offset = currentPage * limit;
      const searchByName = search ? `&name=${search}` : "";

      const endpoint =
        user.permission === "generalPorter"
          ? `/password/paginated/${limit}/${offset}?residentialId=${user.residentialId}&expirationFilter=after${searchByName}`
          : `/password/paginated/${limit}/${offset}?condominiumId=${user.condominiumId}&expirationFilter=after${searchByName}`;
      const response = await api.get(endpoint);

      const { rows, count } = response.data;

      setTotalPages(Math.ceil(count / limit));

      const solicitation: Access[] = rows.map((solicitation: Access) => ({
        ...solicitation,
        expirationTime: solicitation.expirationTime,
        initialTime: solicitation.initialTime,
        solicitationId: solicitation.id,
        password: solicitation.passCode,
      }));

      setGuests(
        solicitation.map((item) => [
          <ActionTd>
            <button
              key={item.id}
              onClick={() => showDetails(item.GuestRegister)}
              className="icon"
            >
              <MdInfoOutline />
            </button>
          </ActionTd>,
          item.GuestRegister.name,
          item.GuestRegister.user.name,
          moment(item.initialTime).format("DD/MM/YYYY HH:mm"),
          moment(item.expirationTime).format("DD/MM/YYYY HH:mm"),
          <ActionTd>
            <button
              className="icon"
              onClick={async () => {
                await api.put("/password/cancelAccess", {
                  id: item.solicitationId,
                });

                await fetchGuests(currentPage, search);
              }}
            >
              <MdDelete color="#a00" />
            </button>
          </ActionTd>,
        ])
      );

      setIsGuestsFetching(false);
    },
    [showDetails, user.condominiumId, user.permission, user.residentialId]
  );

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [currentDetailedGuest, setCurrentDetailedGuest] = useState<Guest>(
    {} as Guest
  );

  useEffect(() => {
    if (search.length > 0) fetchGuests(0, search);
    else if (user.permission) fetchGuests(0);
  }, [search, user]);

  return (
    <Layout>
      <Container>
        <TopMenu />
        <Modal
          title={`Informações do(a) ${guestLabel.toLocaleLowerCase()} ${
            currentDetailedGuest.name
          }`}
          active={isDetailsModalOpen}
          content={
            <GuestDetails
              guest={currentDetailedGuest}
              resident={currentDetailedGuest.user}
              onCall={() => setIsDetailsModalOpen(false)}
            />
          }
          onClose={() => setIsDetailsModalOpen(false)}
        />

        <Table
          loading={isGuestsFetching}
          pageState={[currentPage, setCurrentPage]}
          titles={[
            "",
            guestLabel,
            residentLabel,
            "Data e hora inicial",
            "Data e hora final",
            "Ações",
          ]}
          rows={guests}
          searchPlaceholder={`Pesquisar por nome do ${guestLabel} ou ${residentLabel.toLocaleLowerCase()}...`}
          searchState={[search, setSearch]}
          count={totalPages}
          onPageChange={(item) => fetchGuests(item.selected, search)}
        />
      </Container>
    </Layout>
  );
}
