import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 7fr 2fr;
  height: 100vh;
`;

export const Main = styled.main`
  display: flex;
  padding: 1.25rem;
  min-height: 100vh;
  width: 100%;
  overflow: auto;

  & > div {
    width: 100%;
    height: 100%;
  }
`;
