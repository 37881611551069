import { MdClose } from "react-icons/md";
import { Overlay, Modal as StyledModal, Head, Body } from "./styles";

interface ModalProps {
  active: boolean;
  title?: string;
  content: string | React.ReactNode;
  onClose: () => void;
  style?: React.CSSProperties;
}

export default function Modal({
  active,
  content,
  onClose,
  title,
  style = {} as React.CSSProperties,
}: ModalProps) {
  const headClasses = [title === "" ? "no-title" : ""];

  return (
    (active && (
      <Overlay style={style} onClick={() => onClose()}>
        <StyledModal onClick={(event) => event.stopPropagation()}>
          <Head className={headClasses.join(" ")}>
            {title && <h1>{title}</h1>}
            <button onClick={() => onClose()}>
              <MdClose />
            </button>
          </Head>
          <Body>
            {(typeof content === "string" && <h3>{content}</h3>) || content}
          </Body>
        </StyledModal>
      </Overlay>
    )) || <></>
  );
}
