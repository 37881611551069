import { MouseEventHandler } from "react";
import { Button } from "./styles";
import { BeatLoader } from "react-spinners";

interface ConfirmButtonProps {
  title: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
}

export default function ConfirmButton({
  title,
  onClick,
  loading,
}: ConfirmButtonProps) {
  return (
    <Button disabled={loading} onClick={onClick}>
      {loading ? <BeatLoader color="#fff" /> : title}
    </Button>
  );
}
