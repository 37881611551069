import { useRecoilValue } from "recoil";
import { Card, Image } from "./styles";
import userState from "../../../../states/userState";
import UserPng from "../../../../assets/imgs/user.png";

type Props = {
  imageUUID?: string;
  name: string;
  status: "done" | "pending";
  condominium: string;
  tower: string;
  apartment: string;
  onClick: () => void;
};

export default function PackageCard({
  imageUUID,
  name,
  status,
  condominium,
  tower,
  apartment,
  onClick,
}: Props) {
  const user = useRecoilValue(userState);

  const imgSrc = imageUUID
    ? `https://guugcall-prod.s3-sa-east-1.amazonaws.com/users_images/${imageUUID}.jpg`
    : null;

  const towerLabel = user?.residential?.labelType !== "house" ? "Torre" : "Rua";
  const aptLabel =
    user?.residential?.labelType === "house"
      ? "Casa"
      : user?.residential?.labelType === "commercial"
      ? "Sala"
      : "Apt";

  return (
    <Card onClick={onClick}>
      <div>
        <Image src={imgSrc ?? UserPng} alt="" />
      </div>
      <div>
        <p style={{ fontWeight: "bold" }}>{name}</p>
        <p
          style={{
            color: status === "done" ? "#00af00" : "#bda100",
            fontWeight: "bold",
          }}
        >
          {status === "done" ? "Entregue" : "Pendente"}
        </p>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <p style={{ marginRight: 10 }}>
            <b>Cond: </b>
            {condominium}
          </p>
          <p style={{ marginRight: 10 }}>
            <b>{towerLabel}: </b>
            {tower}
          </p>
          <p style={{ marginRight: 10 }}>
            <b>{aptLabel}: </b>
            {apartment}
          </p>
        </div>
      </div>
    </Card>
  );
}
