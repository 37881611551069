import { toast, ToastOptions } from "react-toastify";

export enum ToastType {
  error = "error",
  success = "success",
  info = "info",
  warn = "warn",
}

export function notify(
  message: string,
  type: ToastType,
  duration: number = 3000
) {
  const toastConfig: ToastOptions = {
    position: "top-right",
    autoClose: duration,
    theme: "light",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  if (type === "error") toast.error(message, toastConfig);
  if (type === "success") toast.success(message, toastConfig);
  if (type === "warn") toast.warn(message, toastConfig);
  if (type === "info") toast.info(message, toastConfig);
}
