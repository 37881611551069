import axios from "axios";
import { decrypt } from "../utils/crypto";

const envType = process.env.REACT_APP_NODE_ENV;

const baseURL =
  envType === "dev"
    ? "https://dev.guug.com.br"
    : envType === "prod"
    ? "https://apiv1.guug.com.br"
    : envType === "local"
    ? "https://localdev.guug.com.br"
    : "";

export const getToken = () =>
  localStorage.getItem("guugwebporter-jwt-token")
    ? decrypt(localStorage.getItem("guugwebporter-jwt-token") as string)
    : null;

export const getAuthorizationHeader = () => `Bearer ${getToken()}`;

export const api = axios.create({
  baseURL,
  headers: { Authorization: getAuthorizationHeader() },
});

api.interceptors.request.use((config) => {
  (config.headers as any).Authorization = getAuthorizationHeader();
  return config;
});
