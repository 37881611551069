import { Container } from "./styles";

interface DateBoxProps {
    time: string;
}

export default function DateBox({ time }: DateBoxProps) {
    return (
        <Container>
            <time>{time}</time>
        </Container>
    );
}
