import styled from "styled-components";

export const Text = styled.p`
  font-size: 1rem;
  color: var(--gray-400);
  font-weight: bold;
  text-align: center;

  &.margin-top {
    margin-top: 1.5rem;
  }
`;
