import { useLocation } from "react-router-dom";
import Button from "../Button";

interface MenuItemProps {
  title: string;
  href: string;
}

export default function MenuItem({ title, href }: MenuItemProps) {
  const isCurrentPathSelected = useLocation().pathname.includes(
    href.split("/")[1]
  );

  const height = window.innerHeight;

  return (
    <li className="menu-item">
      <Button
        active={isCurrentPathSelected}
        title={title}
        size={height < 650 ? "md" : "lg"}
        isLink={true}
        href={href}
      />
    </li>
  );
}
