import { useCallback, useEffect, useState } from "react";
import {
  MdDelete,
  MdDoneOutline,
  MdInfoOutline,
  MdOutlineNotInterested,
} from "react-icons/md";
import { useRecoilState } from "recoil";
import DialogModal from "../../../components/DialogModal";
import { GuestCardInfo } from "../../../components/GuestCard";
import GuestDetails from "../../../components/GuestDetails";
import Layout from "../../../components/Layout";
import Modal from "../../../components/Modal";
import Table from "../../../components/Table";
import Access from "../../../interfaces/Access";
import { Guest } from "../../../interfaces/Guest";
import { api } from "../../../services/api";
import userState from "../../../states/userState";
import { ToastType, notify } from "../../../utils/toast";
import TopMenu from "../components/TopMenu";
import { Container } from "../styles";
import moment from "moment";
import { ActionTd } from "../../../components/Table/styles";

type Status = "accepted" | "denied" | "pending";

const statusData = {
  accepted: { color: "#070", label: "Aprovado" },
  denied: { color: "#a00", label: "Rejeitado" },
  pending: { color: "#009", label: "Pendente" },
};

export default function TodayGuests() {
  const [user] = useRecoilState(userState);

  const residentLabel =
    user?.residential?.labelType !== "commercial"
      ? "Morador"
      : "Pessoa interna";
  const guestLabel =
    user?.residential?.labelType !== "commercial"
      ? "Visitante"
      : "Pessoa externa";

  const [totalPages, setTotalPages] = useState(0);

  const [scheduledGuests, setScheduledGuests] = useState<any[][]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");

  const [isSolicitationsFetching, setIsSolicitationsFetching] =
    useState<boolean>(false);

  const [isDialogModalOpen, setIsDialogModalOpen] = useState<boolean>(false);
  const [dialogModalAsk, setDialogModalAsk] = useState<string>("");
  const [isApproveModal, setIsApproveModal] = useState<boolean>(true);

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [currentGuest, setCurrentGuest] = useState<Guest>({} as Guest);

  /* 
    /password/paginated/:limit/:offset

    query { 
      expirationFilter: [ "after" || "before" ],
      userId: userId
      condominiumId: condominiumId
      residentialId: residentialId
    }
  */

  const showDetails = useCallback((guest: any) => {
    setCurrentGuest(guest);

    setIsDetailsModalOpen(true);
  }, []);

  const fetchSolicitations = useCallback(
    async (currentPage: number, search?: string) => {
      setIsSolicitationsFetching(true);

      const limit = 10;
      const offset = currentPage * limit;
      const searchByName = search ? `&name=${search}` : "";

      const endpoint =
        user.permission === "generalPorter"
          ? `/password/paginated/${limit}/${offset}?residentialId=${user.residentialId}${searchByName}`
          : `/password/paginated/${limit}/${offset}?condominiumId=${user.condominiumId}${searchByName}`;

      const response = await api.get(endpoint);
      const { rows, count } = response.data;

      setTotalPages(Math.ceil(count / limit));

      const solicitations: Access[] = rows.map((solicitation: Access) => ({
        ...solicitation,
        status: solicitation.status,
        solicitationId: solicitation.id,
        password: solicitation.passCode,
      }));

      setScheduledGuests(
        solicitations.map((item) => {
          const isNotCanceled = moment(item.expirationTime).isAfter(moment());

          return [
            <ActionTd>
              <button
                key={item.id}
                onClick={() => showDetails(item.GuestRegister)}
                className="icon"
              >
                <MdInfoOutline />
              </button>
            </ActionTd>,
            item.GuestRegister.name,
            item.GuestRegister.user.name,
            moment(item.initialTime).format("DD/MM/YYYY HH:mm"),
            moment(item.expirationTime).format("DD/MM/YYYY HH:mm"),
            <b
              style={{
                color: isNotCanceled
                  ? statusData[item.status as Status].color
                  : "#a00",
              }}
            >
              {isNotCanceled
                ? statusData[item.status as Status].label
                : "Cancelado"}
            </b>,
            <ActionTd>
              {isNotCanceled && item.status === "pending" && (
                <>
                  <button
                    className="icon"
                    onClick={async () => {
                      await api.put("/password/updateStatus", {
                        id: item.solicitationId,
                        status: "accepted",
                      });

                      await fetchSolicitations(currentPage, search);
                    }}
                  >
                    <MdDoneOutline color="#080" />
                  </button>
                  <button
                    className="icon"
                    onClick={async () => {
                      await api.put("/password/updateStatus", {
                        id: item.solicitationId,
                        status: "denied",
                      });

                      await fetchSolicitations(currentPage, search);
                    }}
                  >
                    <MdOutlineNotInterested color="#a00" />
                  </button>
                </>
              )}

              {isNotCanceled && item.status !== "denied" ? (
                <button
                  className="icon"
                  onClick={async () => {
                    await api.put("/password/cancelAccess", {
                      id: item.solicitationId,
                    });

                    await fetchSolicitations(currentPage, search);
                  }}
                >
                  <MdDelete color="#a00" />
                </button>
              ) : (
                "-"
              )}
            </ActionTd>,
          ];
        })
      );

      setIsSolicitationsFetching(false);
    },
    [showDetails, user.condominiumId, user.permission, user.residentialId]
  );

  const openApproveModal = (guest: Guest) => {
    setCurrentGuest(guest);

    setDialogModalAsk(`Deseja aprovar a entrada de ${guest.name}?`);
    setIsApproveModal(true);

    setIsDialogModalOpen(true);
  };
  const openDenyModal = (guest: Guest) => {
    setCurrentGuest(guest);

    setDialogModalAsk(`Deseja negar a entrada de ${guest.name}?`);
    setIsApproveModal(false);

    setIsDialogModalOpen(true);
  };

  const handleApproveGuest = (guest: Guest) => {
    try {
      api
        .put("/door_open_soliciation/status", {
          id: guest.solicitationId,
          status: "ACCEPTED",
        })
        .then((response) => response.status)
        .then((status) => {
          if (status === 200) {
            fetchSolicitations(currentPage, search);
          }
        });

      notify(
        `Entrada de ${guestLabel.toLocaleLowerCase()} aprovada com sucesso!`,
        ToastType.success
      );
    } catch (e) {
      notify(
        `Erro ao aprovar entrada do ${guestLabel.toLocaleLowerCase()}.`,
        ToastType.error
      );
    }

    setIsDialogModalOpen(false);
  };
  const handleDenyGuest = (guest: Guest) => {
    try {
      api
        .put("/door_open_soliciation/status", {
          id: guest.solicitationId,
          status: "DENIED",
        })
        .then((response) => response.status)
        .then((status) => {
          if (status === 200) {
            fetchSolicitations(currentPage, search);
          }
        });

      notify(
        `Entrada de ${guestLabel.toLocaleLowerCase()} recusada com sucesso!`,
        ToastType.success
      );
    } catch (e) {
      notify(
        `Erro ao recusar entrada do ${guestLabel.toLocaleLowerCase()}.`,
        ToastType.error
      );
    }

    setIsDialogModalOpen(false);
  };

  useEffect(() => {
    if (search.length > 0) fetchSolicitations(0, search);
    else if (user.permission) fetchSolicitations(0);
  }, [search, user]);

  return (
    <Layout>
      <DialogModal
        active={isDialogModalOpen}
        title="Confirmação"
        ask={dialogModalAsk}
        onClose={() => setIsDialogModalOpen(false)}
        confirmAction={() => {
          if (isApproveModal) handleApproveGuest(currentGuest);
          else handleDenyGuest(currentGuest);
        }}
        cancelAction={() => setIsDialogModalOpen(false)}
      />
      <Modal
        title={`Informações do(a) ${guestLabel.toLocaleLowerCase()} ${
          currentGuest.name
        }`}
        active={isDetailsModalOpen}
        content={
          <GuestDetails
            guest={currentGuest}
            resident={currentGuest.user}
            onCall={() => setIsDetailsModalOpen(false)}
          />
        }
        onClose={() => setIsDetailsModalOpen(false)}
      />
      <Container>
        <TopMenu />

        <Table
          loading={isSolicitationsFetching}
          titles={[
            "",
            guestLabel,
            residentLabel,
            "Data e hora inicial",
            "Data e hora final",
            "Status",
            "Ações",
          ]}
          rows={scheduledGuests}
          searchPlaceholder={`Pesquisar por nome do ${guestLabel} ou ${residentLabel.toLocaleLowerCase()}...`}
          count={totalPages}
          onPageChange={(item) => fetchSolicitations(item.selected)}
          pageState={[currentPage, setCurrentPage]}
          searchState={[search, setSearch]}
        />
      </Container>
    </Layout>
  );
}
