import { atom } from "recoil";
import { User } from "../interfaces/User";
import { SelectedUserToChat } from "./selectedUserToChatState";

const selectedUserToCall = atom<SelectedUserToChat | User | null>({
  key: "selectedUserToCall",
  default: null,
});

export default selectedUserToCall;
