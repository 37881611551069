import { Apartment } from "./Apartment";
import { Condominium } from "./Condominium";
import { Residential } from "./Residential";
import { Tower } from "./Tower";

export enum Permission {
  Resident = "resident",
  Janitor = "janitor",
  AptManager = "aptManager",
  LocalPorter = "localPorter",
  GeneralPorter = "generalPorter",
}

export interface User {
  id: number;
  name: string;
  surname?: string;
  phone: string;
  email?: string;
  cpf?: string;
  gender?: string;
  birthday?: string;
  password?: string;
  photo?: string;
  content?: string;
  lastMessageUnseen?: boolean;
  residential: Residential;
  condominium: Condominium;
  tower?: Tower;
  apartment?: Apartment;
  apartmentId?: number;
  towerId?: number;
  condominiumId?: number;
  residentialId: number;
  uuid?: string;
  status?: string;
  imageUUID?: string;
  permission: Permission;
  shouldCallVoip: boolean;
  createdAt?: string;
  updatedAt?: string;
  residentType?: "owner" | "tenant" | "employee";
  company?: string;
}
