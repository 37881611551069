import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { MdInfoOutline } from "react-icons/md";
import { useRecoilState } from "recoil";
import GuestDetails from "../../../components/GuestDetails";
import Layout from "../../../components/Layout";
import LoadingMessage from "../../../components/LoadingMessage";
import Modal from "../../../components/Modal";
import NotFoundMessage from "../../../components/NotFoundMessage";
import Table from "../../../components/Table";
import { Door } from "../../../interfaces/Door";
import { Guest } from "../../../interfaces/Guest";
import { api } from "../../../services/api";
import userState from "../../../states/userState";
import TopMenu from "../components/TopMenu";
import { Container } from "../styles";
import Access from "../../../interfaces/Access";
import { ActionTd } from "../../../components/Table/styles";

export default function GuestHistory() {
  const [user] = useRecoilState(userState);

  const residentLabel =
    user?.residential?.labelType !== "commercial"
      ? "Morador"
      : "Pessoa interna";
  const guestLabel =
    user?.residential?.labelType !== "commercial"
      ? "Visitante"
      : "Pessoa externa";

  const [guests, setGuests] = useState<any[][]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isGuestsFetching, setIsGuestsFetching] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(0);

  const showDetails = useCallback((guest: any) => {
    setCurrentDetailedGuest(guest);

    setIsDetailsModalOpen(true);
  }, []);

  const fetchGuests = useCallback(
    async (currentPage: number, search?: string) => {
      setIsGuestsFetching(true);

      const limit = 10;
      const offset = currentPage * limit;
      const searchByName = search ? `&name=${search}` : "";

      const endpoint =
        user.permission === "generalPorter"
          ? `/password/paginated/${limit}/${offset}?residentialId=${user.residentialId}&expirationFilter=before${searchByName}`
          : `/password/paginated/${limit}/${offset}?condominiumId=${user.condominiumId}&expirationFilter=before${searchByName}`;

      const response = await api.get(endpoint);
      const { rows, count } = response.data;

      setTotalPages(Math.ceil(count / limit));

      const solicitation: Access[] = rows.map((solicitation: Access) => ({
        ...solicitation,
        expirationTime: solicitation.expirationTime,
        solicitationId: solicitation.id,
        password: solicitation.passCode,
      }));

      setGuests(
        solicitation.map((item) => [
          <ActionTd>
            <button
              key={item.id}
              onClick={() => showDetails(item.GuestRegister)}
              className="icon"
            >
              <MdInfoOutline />
            </button>
          </ActionTd>,
          item.GuestRegister.name,
          item.GuestRegister.user.name,
          moment(item.initialTime).format("DD/MM/YYYY HH:mm"),
          moment(item.expirationTime).format("DD/MM/YYYY HH:mm"),
        ])
      );

      setIsGuestsFetching(false);
    },
    [showDetails, user.condominiumId, user.permission, user.residentialId]
  );

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [currentDetailedGuest, setCurrentDetailedGuest] = useState<Guest>(
    {} as Guest
  );

  useEffect(() => {
    if (search.length > 0) fetchGuests(0, search);
    else if (user.permission) fetchGuests(0);
  }, [search, user]);

  return (
    <Layout>
      <Container>
        <TopMenu />
        <Modal
          title={`Informações do(a) ${guestLabel.toLocaleLowerCase()} ${
            currentDetailedGuest.name
          }`}
          active={isDetailsModalOpen}
          content={
            <GuestDetails
              guest={currentDetailedGuest}
              resident={currentDetailedGuest.user}
              onCall={() => setIsDetailsModalOpen(false)}
            />
          }
          onClose={() => setIsDetailsModalOpen(false)}
        />

        <Table
          pageState={[currentPage, setCurrentPage]}
          loading={isGuestsFetching}
          onPageChange={(item) => fetchGuests(item.selected, search)}
          count={totalPages}
          titles={[
            "",
            guestLabel,
            residentLabel,
            "Data e hora inicial",
            "Data e hora final",
          ]}
          rows={guests}
          searchPlaceholder={`Pesquisar por nome do ${guestLabel.toLocaleLowerCase()} ou ${residentLabel.toLocaleLowerCase()}...`}
          searchState={[search, setSearch]}
        />
      </Container>
    </Layout>
  );
}
