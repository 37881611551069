import { useRecoilState } from "recoil";
import Tabs from "../../../../components/Tabs";
import userState from "../../../../states/userState";

export default function TopMenu() {
  const [user] = useRecoilState(userState);

  const guestsLabel =
    user?.residential?.labelType !== "commercial"
      ? "Visitantes"
      : "Pessoas externas";

  return (
    <Tabs
      items={[
        { title: `${guestsLabel} de hoje`, path: "/liberar/hoje" },
        {
          title: `${guestsLabel} agendad${
            guestsLabel === "Visitantes" ? "o" : "a"
          }s`,
          path: "/liberar/agendados",
        },
        { title: "Histórico", path: "/liberar/historico" },
      ]}
    />
  );
}
